import React, {Component} from "react";
import Skeleton from "react-loading-skeleton";

class TestimonialPlaceholderItem extends Component {
    render() {
        return (
            <div className="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="bg-light p-4 mt_30">
                    <Skeleton width={"25px"} height={"20px"} style={{position: "absolute", marginTop: "-10px"}}/>

                    <Skeleton width={"100%"} height={15}/>
                    <Skeleton width={"85%"} height={15}/>
                    <Skeleton width={"95%"} height={15}/>

                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <div>
                            <Skeleton width={80} height={80} circle={true}/>
                        </div>
                        <div>
                            <Skeleton width={"200px"} height={20}/>
                            <Skeleton width={"140px"} height={15}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestimonialPlaceholderItem;