import React from "react";
import {Blur} from "transitions-kit";
import {AsyncImage} from "loadable-image";

import Media from "../../../utils/Media";
import {filename, textMore} from "../../../utils/utils";
import {Link} from "react-router-dom";

export default class BlogItem extends React.Component {
    render() {
        const {data, index} = this.props;
        const imageUrl = Media.imageUrl(data["media"]);

        return (
            <div className={`blog-item effect-item wow fadeIn${index % 2 === 0 ? "Left" : "Right"}`}
                 data-wow-delay="0.2s">
                <Link to={"/blog/" + data["slug"]} className="image-effect">
                    <div className="blog-image">
                        <AsyncImage src={imageUrl}
                                    alt={filename(imageUrl)}
                                    Transition={Blur}
                                    style={{width: "100%", height: "auto", aspectRatio: 16/9}}/>
                    </div>
                </Link>
                <div className="blog-text">
                    <div className="blog-cat">Category: {data["category"]["category_name"]}</div>
                    <h3>
                        <Link to={"/blog/" + data["slug"]}>{textMore(data["title"])}</Link>
                    </h3>
                    <div className="blog-author mb_10">
                        <span><i className="fa fa-user"/> {data["posted_by"]}</span>
                        <span><i className="fa fa-calendar-o"/> {data["posted_on"]}</span>
                    </div>
                    <p>{textMore(data["summary"])}</p>
                    <Link className="sm_btn" to={"/blog/" + data["slug"]}>Learn more →</Link>
                </div>
            </div>
        );
    }
}
