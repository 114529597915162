import React from "react";
import Media from "../../../utils/Media";
import {Blur} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import {Link} from "react-router-dom";
import SocialLink from "./SocialLink";

export default class TeamItem extends React.Component {
    render() {
        const {data, isPage} = this.props;
        const imageUrl = Media.imageUrl(data["media"]);

        return (
            <div className="team-item wow fadeInLeft" style={(isPage ? null : {marginLeft: "15px", marginRight: "15px"})}
                 data-wow-delay="0.2s">
                <div className="team-photo">
                    <AsyncImage src={imageUrl}
                                Transition={Blur}
                                style={{width: "100%", height: "auto", aspectRatio: 0.90}}/>
                </div>
                <div className="team-text">
                    <Link to={"/team/" + data["id"]}>{data["first_name"]} {data["last_name"]}</Link>
                    <p>{data["designation"]}</p>
                </div>
                <div className="team-social">
                    <SocialLink data={data["social_urls"]} />
                </div>
            </div>
        )
    }
}