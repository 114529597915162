import React from "react";
import {Link} from "react-router-dom";
import {withSettings} from "../../utils/SettingsProvider";
import {backendUrl, getSetting} from "../../utils/utils";
import Skeleton from "react-loading-skeleton";

class Menu extends React.Component {

    dropdownMenu(index, item) {
        if (item["status"] === "0") return;

        const children = item["children"];
        return (
            <li key={index} className="menu-item-has-children"><a href="#">{item["name"]}</a>
                <ul className="sub-menu">
                    {children.map((item1, index1) => (
                        item1["children"] ? (
                            this.dropdownMenu(index1, item1)
                        ) : (
                            item1["status"] === "1" ? (
                                <li key={index1}>
                                    <Link to={item1["url"]}>{item1["name"]}</Link>
                                </li>
                            ) : null
                        )
                    ))}
                </ul>
            </li>
        );
    }

    megaMenu(index, item) {
        if (item["status"] === "0") return;

        const children = item["children"];
        return (
            <li key={index} className="menu-static"><a className="menu-item-has-children" href="#">{item["name"]}</a>
                <div className="mega-menu">
                    <ul style={{width: '100%', columnCount: 3}}>
                        {children.map((item1, index1) => (
                            item1["children"] ? (
                                this.megaMenu(index1, item1)
                            ) : (
                                item1["status"] === "1" ? (
                                    <li key={index1}>
                                        <Link to={item1["url"]}>{item1["name"]}</Link>
                                    </li>
                                ) : null
                            )
                        ))}
                    </ul>
                </div>
            </li>
        );
    }

    render() {
        const {settings} = this.props;

        const logo = getSetting(settings, "SETTING_SITE_LOGO");

        let menu = getSetting(settings, "MAIN_MENU");

        if (menu) {
            menu = JSON.parse(menu);

            console.log(menu);
        }

        return (
            <div id="strickymenu" className="menu-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="logo flex">
                                {logo ? (
                                    <Link to="/">
                                        <img src={backendUrl("/" + logo)}
                                             alt="Logo"
                                        />
                                    </Link>
                                ) : (
                                    <Skeleton width={"172px"} height={"62px"}/>
                                )}
                            </div>
                        </div>
                        <div className="col-md-9 col-6">
                            <div className="main-menu">
                                <ul className="nav-menu">
                                    {menu ? (
                                        menu.map((item, index) => (
                                            item["children"] ? (
                                                item["url"] === '#mega' ? this.megaMenu(index, item) : this.dropdownMenu(index, item)
                                            ) : (
                                                item["status"] === "1" ? (
                                                    <li key={index}><Link
                                                        to={item["url"] ?? "/"}>{item["name"]}</Link>
                                                    </li>
                                                ) : null
                                            )
                                        ))
                                    ) : (
                                        <>
                                            <li className="mt-4 mx-2"><Skeleton width={"100px"} height={"30px"}/></li>
                                            <li className="mt-4 mx-2"><Skeleton width={"100px"} height={"30px"}/></li>
                                            <li className="mt-4 mx-2"><Skeleton width={"100px"} height={"30px"}/></li>
                                            <li className="mt-4 mx-2"><Skeleton width={"100px"} height={"30px"}/></li>
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div className="searchbar">
                                <div className="search-button"><i className="fa fa-search"/>
                                </div>
                                <div id="myOverlay" className="overlay">
                                    <span className="closebtn" title="Close Overlay">×</span>
                                    <div className="overlay-content">
                                        <form action="">
                                            <input type="text" placeholder="Search.." name="search"/>
                                            <button type="submit"><i className="fa fa-search"/></button>
                                        </form>
                                    </div>
                                </div>
                                <div className="cart-button"><i className="fa fa-shopping-cart"/>
                                    <div className="wishlist-dropdown">
                                        <ul>
                                            <li className="first-wishlist">
                                                <div className="wish-text">
                                                    <p><b>2 Items</b></p>
                                                </div>
                                                <div className="wish-img">
                                                    <a href="#">View Cart</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wish-text">
                                                    <a href="#">Product Name</a>
                                                    <p>1 x $99.00</p>
                                                </div>
                                                <div className="wish-img">
                                                    <img src="" alt=""/>
                                                    <span className="close-btn">x</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wish-text">
                                                    <a href="#">Product Name</a>
                                                    <p>1 x $99.00</p>
                                                </div>
                                                <div className="wish-img">
                                                    <img src="" alt=""/>
                                                    <span className="close-btn">x</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wish-text">
                                                    <p><b>Total</b></p>
                                                </div>
                                                <div className="wish-img">
                                                    <span><b>$85.00</b></span>
                                                </div>
                                                <div className="wish-cbtn">
                                                    <a href="#">Checkout</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="mobile-menuicon">
                                <span className="menu-bar"><i className="fa fa-bars" aria-hidden="true"/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSettings(Menu);