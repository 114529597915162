import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Footer from "../components/Footer";
import Partner from "../components/section_elements/partner/Partner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import api from "../utils/api";
import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import Skeleton from "react-loading-skeleton";
import Media from "../utils/Media";
import {AsyncImage} from "loadable-image";
import {Blur} from "transitions-kit";
import {Link} from "react-router-dom";
import {setPageTitle} from "../utils/utils";

class CaseStudy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/case-studies")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Case Studies");
    }

    caseStudyItem(data, index) {
        const imageUrl = Media.imageUrl(data["media"]);

        return (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="pop-service-item">
                    <Link to={"/case-studies/" + data["slug"]}>
                        <AsyncImage src={imageUrl}
                                    Transition={Blur}
                                    style={{width: "100%", height: "auto", aspectRatio: 1.0}}/>
                        <div className="pop-service-text flex">
                            <span className="up-arrow"></span>
                            <p>{data["title"]}</p>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }

    caseStudyPlaceholderItem() {
        return (
            <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
                <Skeleton width={"100%"} height={"200px"}/>
            </div>
        );
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }


        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Case Studies"} banner={null}/>
                <div className="case-study-area pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            {loading ? (
                                <>
                                    {this.caseStudyPlaceholderItem()}
                                    {this.caseStudyPlaceholderItem()}
                                    {this.caseStudyPlaceholderItem()}
                                    {this.caseStudyPlaceholderItem()}
                                </>
                            ) : (
                                data.map((item, index) => (
                                    this.caseStudyItem(item, index)
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default CaseStudy;