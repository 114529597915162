import React, {Component} from "react";
import api from "../utils/api";
import {getSetting, scrollToTop, setPageTitle} from "../utils/utils";
import Error from "../components/Error";
import NotFound from "./NotFound";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import Skeleton from "react-loading-skeleton";
import Slider from "../components/event_detail/Slider";
import {Link} from "react-router-dom";
import {withSettings} from "../utils/SettingsProvider";

class EventDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
            option: 'information',
        };
    }

    componentDidMount() {
        const {slug} = this.props.match.params;
        this.fetchData(slug);
    }

    fetchData(slug) {
        api.get("/events/" + slug)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        // Scroll the document to the top
        scrollToTop();
    }

    handleInformation(option) {
        this.setState({
            option: option,
        })
    }

    render() {
        const {data, loading, error, option} = this.state;
        const {settings} = this.props;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <NotFound/>
        }

        if (!loading) {
            // Set Document Title
            setPageTitle("Event | " + data["event_name"]);
        }

        const contact_title = getSetting(settings, "SETTING_CONTACT_TITLE");
        const contact_phone = getSetting(settings, "SETTING_CONTACT_PHONE");
        const contact_email = getSetting(settings, "SETTING_CONTACT_EMAIL");
        const contact_address = getSetting(settings, "SETTING_CONTACT_ADDRESS");

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={data["event_name"]} banner={null}/>
                <div className="event-detail-area pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-lg-8 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="event-detail-text pt_30">
                                    {/* Slider */}
                                    {loading ? (
                                        <Skeleton width={"100%"} height={300}/>
                                    ) : (
                                        <Slider data={data["slider_images"]}/>
                                    )}

                                    {/* Service Detail */}
                                    {loading ? (
                                        <>
                                            <Skeleton width={"60%"} height={40} className="mb-4"/>
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"80%"}/>
                                            <Skeleton width={"96%"}/>
                                            <Skeleton width={"100%"} className="mt-5"/>
                                            <Skeleton width={"96%"}/>
                                            <Skeleton width={"100%"}/>
                                        </>
                                    ) : (
                                        <>
                                            <h2>{data["event_name"]}</h2>
                                            <div dangerouslySetInnerHTML={{__html: data["description"]}}/>
                                        </>
                                    )}
                                </div>

                                <div className="team-exp-area bg-area pt_70 pb_70">
                                    <div className="container">
                                        <div className="row ov_hidden">
                                            {loading ? (
                                                <div className="col-md-12">
                                                    <Skeleton width={"100%"} height={240} className="mt-4"/>
                                                </div>
                                            ) : (
                                                <div className="col-md-12 wow fadeInUp" data-wow-delay="0.2s">
                                                    <div className="event-detail-tab mt_50">
                                                        <ul className="nav nav-tabs">
                                                            <li className="active">
                                                                <button
                                                                    onClick={() => this.handleInformation('information')}
                                                                    className={option === 'information' ? 'active' : null}>My
                                                                    Information
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button onClick={() => this.handleInformation('map')}
                                                                        className={option === 'map' ? 'active' : null}
                                                                        data-toggle="tab">Location Map
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    onClick={() => this.handleInformation('contact')}
                                                                    className={option === 'contact' ? 'active' : null}
                                                                    data-toggle="tab">Contact Form
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="tab-content event-detail-content">
                                                        <div
                                                            className={option === 'information' ? 'tab-pane fade show active' : 'tab-pane fade'}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="event-info">
                                                                        <ul>
                                                                            <li>
                                                                                <span>Organized by:</span>{data["organized_by"]}
                                                                            </li>
                                                                            <li>
                                                                                <span>Location:</span> {data["location"]}
                                                                            </li>
                                                                            <li><span>Date:</span> {data["event_date"]}
                                                                            </li>
                                                                            <li>
                                                                                <span>Start Time:</span> {data["start_time"]}
                                                                            </li>
                                                                            <li>
                                                                                <span>End Time:</span> {data["end_time"]}
                                                                            </li>
                                                                            <li><span>Email:</span> {data["email"]}</li>
                                                                            <li><span>Phone:</span> {data["phone"]}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={option === 'map' ? 'tab-pane fade show active' : 'tab-pane fade'}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: data["location_map"]}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={option === 'contact' ? 'tab-pane fade show active' : 'tab-pane fade'}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="event-form">
                                                                        <form>
                                                                            <div className="form-row row">
                                                                                <div className="form-group col-md-12">
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="Full Name"/>
                                                                                </div>
                                                                                <div className="form-group col-md-6">
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="Phone Number"/>
                                                                                </div>
                                                                                <div className="form-group col-md-6">
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="Email Address"/>
                                                                                </div>
                                                                                <div className="form-group col-12">
                                                                                    <textarea className="form-control"
                                                                                              placeholder="Message"></textarea>
                                                                                </div>

                                                                                <div className="form-group col-12">
                                                                                    <button type="submit"
                                                                                            className="btn">Send Message
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="service-sidebar">
                                    <div className="service-widget-contact mt-4">
                                        <h2>Contact Us</h2>
                                        <p>{contact_title}</p>
                                        <ul>
                                            <li><i className="fa fa-volume-control-phone"></i> {contact_phone}</li>
                                            <li><i className="fa fa-envelope"></i> {contact_email}</li>
                                            <li><i className="fa fa-map-marker"></i> {contact_address}</li>
                                        </ul>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </div>
                                    <div className="service-qucikcontact event-form mt_30">
                                        <h3>Quick Contact</h3>
                                        <form>
                                            <div className="form-row row">
                                                <div className="form-group col-md-12">
                                                    <input type="text" className="form-control"
                                                           placeholder="Full Name"/>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="text" className="form-control"
                                                           placeholder="Phone Number"/>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input type="text" className="form-control"
                                                           placeholder="Email Address"/>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <textarea className="form-control" placeholder="Message"></textarea>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <button type="submit" className="btn">Send Message</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default withSettings(EventDetail);