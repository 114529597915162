import React from "react";
import api from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import Error from "../Error";
import {API_DATA_NOT_AVAILABLE} from "../../utils/constants";
import {textMore} from "../../utils/utils";
import Media from "../../utils/Media";
import {Link} from "react-router-dom";

class RecentPost extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/blogs?limit=3&order=desc")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    postItem(data, index) {
        const imageUrl = Media.imageUrl(data["media"], 'small');

        return (
            <div className="blog-recent-item" key={index}>
                <div className="blog-recent-photo">
                    <Link to={"/blog/" + data["slug"]}><img src={imageUrl} alt=""/></Link>
                </div>
                <div className="blog-recent-text">
                    <Link to={"/blog/" + data["slug"]} title={data["title"]}>{textMore(data["title"], 35)}</Link>
                    <div className="blog-post-date">{data["posted_on"]}</div>
                </div>
            </div>
        );
    }

    postPlaceholderItem(weight = "100px") {
        return (
            <div className="blog-recent-item">
                <div className="blog-recent-photo">
                    <Skeleton width={"100%"} height={55}/>
                </div>
                <div className="blog-recent-text">
                    <Skeleton width={weight} height={25}/>
                    <Skeleton width={"90px"} height={15}/>
                </div>
            </div>
        );
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <div className="sidebar-item">
                <h3>Recent Post</h3>

                {loading ? (
                    <>
                        {this.postPlaceholderItem("120px")}
                        {this.postPlaceholderItem("100px")}
                        {this.postPlaceholderItem("125px")}
                    </>
                ) : (
                    data.map((item, index) => (
                        this.postItem(item, index)
                    ))
                )}
            </div>
        );
    }
}

export default RecentPost;