import React from "react";

export default class ServiceItem extends React.Component {
    render() {
        const {data} = this.props;

        return (
            <div className="col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="feature-item">
                    <div className="feature-icon">
                        <i className={data["icon"]} aria-hidden="true"/>
                    </div>
                    <div className="feature-text">
                        <h4>{data["service_name"]}</h4>
                        <p>{data["short_description"]}</p>
                        <a href="">Read more</a>
                    </div>
                </div>
            </div>
        );
    }
}
