import React from "react";
import Skeleton from "react-loading-skeleton";

export default class BlogPlaceholderItem extends React.Component {
    render() {
        return (
            <div style={{width:"33%", float: "left", padding: "15px"}} className={`effect-item wow fadeIn${this.props.index % 2 === 0 ? "Left" : "Right"}`}
                 data-wow-delay="0.2s">
                <div className="image-effect">
                    <div className="blog-image">
                        <Skeleton width={"100%"} height={200}/>
                    </div>
                </div>
                <div className="blog-text">
                    <div className="blog-cat">
                        <Skeleton width={100} height={20}/>
                    </div>
                    <h3>
                        <Skeleton width={"100%"} height={30}/>
                    </h3>
                    <div className="blog-author mb_10">
                        <span><Skeleton width={120} height={20}/></span>
                        <span><Skeleton width={100} height={20}/></span>
                    </div>
                    <p>
                        <Skeleton width={"100%"}/>
                        <Skeleton width={"100%"}/>
                    </p>
                    <Skeleton width={100} height={20}/>
                </div>
            </div>
        );
    }
}
