import React, {Component} from "react";
import api from "../../utils/api";
import Error from "../Error";
import {API_DATA_NOT_AVAILABLE} from "../../utils/constants";
import ServicePlaceholderItem from "../service/ServicePlaceholderItem";
import ServiceItem from "../service/ServiceItem";

class LatestService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const limit = 3;
        const order = "desc";

        api.get("/services", {
            params: {
                limit,
                order,
            }
        })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <div className="row ov_hidden pt_70 pb_70">
                <div className="col-md-12">
                    <div className="main-headline wow fadeInDown" data-wow-delay="0.2s">
                        <h1><span>Latest</span> Service</h1>
                    </div>
                </div>
                <div className="row s-border wow fadeInUp" data-wow-delay="0.1s">
                    {loading ? (
                        <>
                            <ServicePlaceholderItem/>
                            <ServicePlaceholderItem/>
                            <ServicePlaceholderItem/>
                        </>
                    ) : (
                        data.map((item, index) => (
                            <ServiceItem key={index} data={item}/>
                        ))
                    )}
                </div>
            </div>
        );
    }
}

export default LatestService;