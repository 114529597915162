import React, {Component} from "react";
import {currency, filename, getTags} from "../../utils/utils";
import Media from "../../utils/Media";
import {Link} from "react-router-dom";

class CareerItem extends Component {
    render() {
        const {job_title, company, salary_from, salary_to, location, tags, media, slug} = this.props["data"];
        const imageUrl = Media.imageUrl(media);
        const tagsList = getTags(tags);

        return (
            <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="job-item">
                    <div className="job-item-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="job-img">
                                    <img src={imageUrl} alt={filename(imageUrl)}/>
                                </div>
                                <div className="job-wrapper-text">
                                    <h4>{job_title}</h4>
                                    <p>{company}</p>
                                    <ul>
                                        <li>
                                            <i className="fa fa-cc-paypal"></i>&nbsp;
                                            {salary_from} - {salary_to} {currency()}
                                        </li>
                                        <li><i className="fa fa-map-marker"></i>&nbsp; {location}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="job-item-btn">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-heart-o"></i></a>
                                        </li>
                                        <li><Link to={"career/" + slug}>Job Detail</Link></li>
                                        <li><a href="">Apply</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="job-item-keyword">
                        <ul>
                            <li><i className="fa fa-tags"></i>Keywords :</li>
                            {tagsList.length > 0 && (
                                tagsList.map((tag, index) => (
                                    <li key={index}>
                                        <Link to={""}><u>{tag.trim()}</u></Link>
                                    </li>
                                ))
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default CareerItem;