import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import Team from "../components/section_elements/team/Team";
import Counter from "../components/section_elements/counter/Counter";
import AboutUsComponent from "../components/section_elements/about_us/AboutUs";
import Mission from "../components/section_elements/mission/Mission";
import Vision from "../components/section_elements/vision/Vision";
import {setPageTitle} from "../utils/utils";

class AboutUs extends Component {
    componentDidMount() {
        setPageTitle("About Us");
    }

    render() {
        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"About Us"} banner={null}/>
                <AboutUsComponent/>
                <Mission/>
                <Vision/>
                <Counter/>
                <Team/>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default AboutUs;