import React, {Component} from "react";
import {Link} from "react-router-dom";

class ServiceItem extends Component {
    render() {
        const {data} = this.props;

        return (
            <div className="col-md-4 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="service-item2 flex mt_30">
                    <i className="fa fa-recycle"></i>
                    <h3>{data["service_name"]}</h3>
                    <p>{data["short_description"]}</p>
                    <Link to={"/service/" + data["slug"]}>Learn more →</Link>
                </div>
            </div>
        );
    }
}

export default ServiceItem;