import React, {Component} from "react";
import Media from "../../utils/Media";

class TestimonialItem extends Component {
    render() {
        const {data} = this.props;
        const imageUrl = Media.imageUrl(data["media"]);

        return (
            <div className="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="testimonial-item mt_30" style={{backgroundImage: "url(img/shape-3.png)"}}>
                    <p>{data["summary"]}</p>
                    <div className="testi-info">
                        <img src={imageUrl} alt=""/>
                        <h4>{data["name"]}</h4>
                        <span>{data["company"]}</span>
                    </div>
                    {/*<div className="testi-link">*/}
                    {/*    <a href="#"><i className="fa fa-long-arrow-right"></i></a>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default TestimonialItem;