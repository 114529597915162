import React from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";

import Page from "./pages/Page";
import Home from "./pages/Home";
import {SettingsProvider} from "./utils/SettingsProvider";
import NotFound from "./pages/NotFound";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import Faq from "./pages/Faq";
import Testimonial from "./pages/Testimonial";
import ContactUs from "./pages/ContactUs";
import PhotoGallery from "./pages/PhotoGallery";
import VideoGallery from "./pages/VideoGallery";
import Service from "./pages/Service";
import ServiceDetail from "./pages/ServiceDetail";
import Team from "./pages/Team";
import TeamDetail from "./pages/TeamDetail";
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import CareerDetail from "./pages/CareerDetail";
import Package from "./pages/Package";
import Event from "./pages/Event";
import EventDetail from "./pages/EventDetail";
import CaseStudy from "./pages/CaseStudy";
import CaseStudyDetail from "./pages/CaseStudyDetail";

export default class App extends React.Component {
    render() {
        return (
            <SettingsProvider>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/faq" component={Faq}/>
                        <Route exact path="/testimonial" component={Testimonial}/>
                        <Route exact path="/contact-us" component={ContactUs}/>
                        <Route exact path="/about-us" component={AboutUs}/>

                        <Route path="/blog/:slug" component={BlogDetail}/>
                        <Route exact path="/blog" component={Blog}/>

                        <Route path="/photo-gallery/:category_name" component={PhotoGallery}/>
                        <Route exact path="/photo-gallery" component={PhotoGallery}/>

                        <Route path="/video-gallery/:category_name" component={VideoGallery}/>
                        <Route exact path="/video-gallery" component={VideoGallery}/>

                        <Route path="/service/:slug" component={ServiceDetail}/>
                        <Route exact path="/service" component={Service}/>

                        <Route path="/team/:id" component={TeamDetail}/>
                        <Route exact path="/team" component={Team}/>

                        <Route path="/career/:slug" component={CareerDetail}/>
                        <Route exact path="/career" component={Career}/>

                        <Route exact path="/package" component={Package}/>

                        <Route path="/event/:slug" component={EventDetail}/>
                        <Route exact path="/event" component={Event}/>

                        <Route path="/case-studies/:slug" component={CaseStudyDetail}/>
                        <Route exact path="/case-studies" component={CaseStudy}/>

                        <Route path="/:page_name" component={Page}/>
                        <Route path="/404" component={NotFound}/>
                    </Switch>
                </BrowserRouter>
            </SettingsProvider>
        );
    }
}
