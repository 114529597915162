// src/utils/api.js
import axios from "axios";
import config from "../config/config";

const api = axios.create({
    baseURL: config.apiUrl,
    // timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        "Authorization": config.apiKey,
    },
});

export default api;
