import React, {Component} from "react";

class SocialLink extends Component {
    render() {
        const {data} = this.props;

        return (
            <ul>
                {/* FACEBOOK */}
                <li><a href={data[0] ?? null} className="text-white"><i className="fa fa-facebook"></i></a>
                </li>

                {/* LINKEDIN */}
                <li><a href={data[1] ?? null} className="text-white"><i className="fa fa-linkedin"></i></a>
                </li>

                {/* TWITTER */}
                <li><a href={data[2] ?? null} className="text-white"><i className="fa fa-twitter"></i></a>
                </li>

                {/* INSTAGRAM */}
                <li><a href={data[3] ?? null} className="text-white"><i className="fa fa-instagram"></i></a>
                </li>

                {/* YOUTUBE */}
                <li><a href={data[4] ?? null} className="text-white"><i className="fa fa-youtube"></i></a>
                </li>
            </ul>
        );
    }
}

export default SocialLink;