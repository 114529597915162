import React, {Component} from "react";
import Skeleton from "react-loading-skeleton";

class ServicePlaceholderItem extends Component {
    render() {
        return (
            <div className="col-md-4 wow fadeInLeft" data-wow-delay="0.2s">
                <Skeleton width={"100%"} height={300} className="mb-4"/>
            </div>
        );
    }
}

export default ServicePlaceholderItem;