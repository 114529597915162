import React from "react";

export default class Error extends React.Component {
    render() {
        const {message} = this.props;

        return (
            <div style={{
                color: "#856060",
                background: "#f1e6e6",
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.2em"
            }}>
                {message}
            </div>
        );
    }
}
