import React, {Component} from "react";
import {getYoutubeVideoId, getYoutubeVideoThumbnail} from "../../utils/utils";

class VideoGalleryItem extends Component {
    render() {
        const {data} = this.props;
        const videoUrl = data["media_url"];
        const videoId = videoUrl ? getYoutubeVideoId(videoUrl) : null;
        const thumbnail = getYoutubeVideoThumbnail(videoId);

        return (
            <div className="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="2, 3" data-sort="Menu">
                <div className="video-item mt_30">
                    <div className="video-img">
                        <img src={thumbnail} alt={thumbnail}/>
                        <div className="video-section">
                            <a className="video-button mgVideo" target="_blank" href={videoUrl}><span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoGalleryItem;