import React from "react";
import {backendUrl, getSetting} from "../utils/utils";
import {withSettings} from "../utils/SettingsProvider";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

class Footer extends React.Component {
    render() {
        const {settings} = this.props;
        const loading = settings.length;

        const logo = getSetting(settings, "SETTING_SITE_LOGO");
        const phone = getSetting(settings, "SETTING_CONTACT_PHONE");
        const email = getSetting(settings, "SETTING_CONTACT_EMAIL");
        const address = getSetting(settings, "SETTING_CONTACT_ADDRESS");
        const about = getSetting(settings, "SETTING_SITE_DESCRIPTION");
        const copyright = getSetting(settings, "SETTING_SITE_COPYRIGHT");

        const facebook = getSetting(settings, "SETTING_SOCIAL_FACEBOOK");
        const instagram = getSetting(settings, "SETTING_SOCIAL_INSTAGRAM");
        const linkedin = getSetting(settings, "SETTING_SOCIAL_LINKEDIN");
        const twitter = getSetting(settings, "SETTING_SOCIAL_TWITTER");
        const youtube = getSetting(settings, "SETTING_SOCIAL_YOUTUBE");

        return (
            <div className="main-footer">
                {/* Top Footer */}
                <div className="top-footer">
                    <div className="container">
                        <div className="row">
                            {/* Left Side */}
                            <div className="col-lg-3 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="footer-logo">
                                    <div className="topfooter-bg"/>
                                    {logo ? (
                                        <Link to="/">
                                            <img src={backendUrl("/" + logo)}
                                                 alt="Logo"
                                            />
                                        </Link>
                                    ) : (
                                        <Skeleton width={"172px"} height={"62px"}/>
                                    )}
                                </div>
                            </div>

                            {/* Right Side */}
                            <div className="col-lg-9 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="footer-address">
                                    <ul>
                                        <li>
                                            <i className="fa fa-envelope"/>
                                            <h5>Email Address</h5>
                                            <p>
                                                {loading ? email : (
                                                    <Skeleton width={"100%"} height={"20px"}/>
                                                )}
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa fa-volume-control-phone"/>
                                            <h5>Phone Number</h5>
                                            <p>
                                                {loading ? phone : (
                                                    <Skeleton width={"100%"} height={"20px"}/>
                                                )}
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa fa-map-marker"/>
                                            <h5>Visit Us</h5>
                                            <p>
                                                {loading ? address : (
                                                    <Skeleton width={"100%"} height={"20px"}/>
                                                )}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Area */}
                <div className="footer-area" style={{backgroundImage: 'url(app/img/shape-2.png)'}}>
                    <div className="container">
                        <div className="row ov_hidden">
                            {/* About Us */}
                            <div className="col-md-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="footer-item">
                                    <h3>About Us</h3>
                                    <div className="textwidget">
                                        <p>
                                            {loading ? about : (
                                                <>
                                                    <Skeleton width={"100%"} height={"20px"}/>
                                                    <Skeleton width={"100%"} height={"20px"}/>
                                                    <Skeleton width={"90%"} height={"20px"}/>
                                                    <Skeleton width={"95%"} height={"20px"}/>
                                                </>
                                            )}
                                         </p>
                                        <Link className="sm_fbtn" to={"/about-us"}>Learn more →</Link>
                                    </div>
                                </div>
                            </div>

                            {/* Important Links */}
                            <div className="col-md-4 col-sm-6 wow fadeInDown" data-wow-delay="0.2s">
                                <div className="footer-item">
                                    <h3>Important Links</h3>
                                    <ul>
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li><Link to={"/about-us"}>About Us</Link></li>
                                        <li><Link to={"/contact-us"}>Contact Us</Link></li>
                                        <li><Link to={"/blog"}>Blogs</Link></li>
                                        <li><Link to={"/faq"}>FAQ</Link></li>
                                    </ul>
                                    <ul>
                                        <li><Link to={"/case-studies"}>Case Studies</Link></li>
                                        <li><Link to={"/career"}>Career</Link></li>
                                        <li><Link to={"/team"}>Teams</Link></li>
                                        <li><Link to={"/service"}>Services</Link></li>
                                        <li><Link to={"/event"}>Events</Link></li>
                                    </ul>
                                </div>
                            </div>

                            {/* Recent Post */}
                            <div className="col-md-4 col-sm-6 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="footer-item">
                                    <h3>Important Links</h3>
                                    <ul>
                                        <li><Link to={"/video-gallery"}>Video Gallery</Link></li>
                                        <li><Link to={"/photo-gallery"}>Photo Gallery</Link></li>
                                        <li><Link to={"/testimonial"}>Testimonial</Link></li>
                                        <li><Link to={"/package"}>Package</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Copyright */}
                <div className="footer-copyrignt">
                    <div className="container">
                        <div className="row ov_hidden">
                            {/* Left Side */}
                            <div className="col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="copyright-text">
                                    <p>
                                        {loading ? copyright : (
                                            <Skeleton width={"300px"} height={"20px"}/>
                                        )}
                                    </p>
                                </div>
                            </div>

                            {/* Right Side */}
                            <div className="col-md-6 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="footer-social">
                                    {loading ? (
                                        <>
                                            <a href={facebook} target="_blank" className="text-white"><i className="fa fa-facebook"/></a>
                                            <a href={instagram} target="_blank" className="text-white"><i className="fa fa-instagram"/></a>
                                            <a href={linkedin} target="_blank" className="text-white"><i className="fa fa-linkedin"/></a>
                                            <a href={twitter} target="_blank" className="text-white"><i className="fa fa-twitter"/></a>
                                            <a href={youtube} target="_blank" className="text-white"><i className="fa fa-youtube"/></a>
                                        </>
                                    ) : (
                                        <Skeleton width={"200px"} height={"30px"}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSettings(Footer);