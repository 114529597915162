import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import api from "../utils/api";
import Error from "../components/Error";
import Skeleton from "react-loading-skeleton";
import {setPageTitle} from "../utils/utils";
import ContactForm from "../components/ContactForm";

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/CONTACT_US")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Contact Us");
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Contact Us"} banner={null}/>
                <div className="container">
                    <div className="row ov_hidden">
                        <div className="col-md-7 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="about1-text mt_30">
                                {loading ? (
                                    <>
                                        <Skeleton width={200} height={35}/>
                                        <br/>
                                        <Skeleton width={"100%"} height={15}/>
                                        <Skeleton width={"90%"} height={15}/>
                                        <Skeleton width={"98%"} height={15}/>
                                        <br/>
                                    </>
                                ) : (
                                    <>
                                        <h1>{data["value"]["title"]}</h1>
                                        <p className="mb_30"
                                           dangerouslySetInnerHTML={{__html: data["value"]["subtitle"]}}/>
                                    </>
                                )}
                            </div>
                         <ContactForm pageName={"contact-us"}/>
                        </div>
                        <div className="col-md-5">
                            <div className="contact-info-item bg1 mb_30 mt_30 wow fadeInRight" data-wow-delay="0.2s">
                                {loading ? (
                                    <Skeleton width={"100%"} height={120}/>
                                ) : (
                                    <div className="contact-info">
                                        <span>
                                            <i className="fa fa-phone"></i> Phone:
                                        </span>
                                            <div className="contact-text"
                                                 dangerouslySetInnerHTML={{__html: data["value"]["phone"]}}/>
                                    </div>
                                )}
                            </div>
                            <div className="contact-info-item bg2 mb_30 wow fadeInRight" data-wow-delay="0.3s">
                                {loading ? (
                                    <Skeleton width={"100%"} height={120}/>
                                ) : (
                                    <div className="contact-info">
                                        <span>
                                            <i className="fa fa-envelope"></i> Email:
                                        </span>
                                        <div className="contact-text"
                                             dangerouslySetInnerHTML={{__html: data["value"]["email"]}}/>
                                    </div>
                                )}
                            </div>
                            <div className="contact-info-item bg3 mb_30 wow fadeInRight" data-wow-delay="0.4s">
                                {loading ? (
                                    <Skeleton width={"100%"} height={120}/>
                                ) : (
                                    <div className="contact-info">
                                        <span>
                                             <i className="fa fa-skype"></i> Skype:
                                        </span>
                                        <div className="contact-text"
                                             dangerouslySetInnerHTML={{__html: data["value"]["skype"]}}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="map-area wow fadeInUp" data-wow-delay="0.2s">
                    {loading ? (
                        <Skeleton width={"100%"} height={360}/>
                    ) : (
                        <div className="google-map" dangerouslySetInnerHTML={{__html: data["value"]["map"]}}/>
                    )}
                </div>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default ContactUs;