import React from "react";
import Skeleton from "react-loading-skeleton";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";

export default class Counter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/COUNTER")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    counterItem(icon, value, title, i) {
        return (
            <div key={i} className="col-lg-3 col-6 mt_30 wow fadeInDown" data-wow-delay="0.2s">
                <div className="counter-item">
                    <div className="counter-icon">
                        <i className={icon}></i>
                    </div>
                    <h2 className="counter">{value}</h2>
                    <h4>{title}</h4>
                </div>
            </div>
        )
    }

    counterPlaceholderItem() {
        return (
            <div className="col-lg-3 col-6 mt_30 wow fadeInDown" data-wow-delay="0.2s">
                <div className="counter-item">
                    <Skeleton width={"60px"} height={"60px"} circle={true}/>
                    <div className="my-2">
                        <Skeleton width={"100px"} height={"30px"}/>
                    </div>
                    <Skeleton width={"200px"} height={"25px"}/>
                </div>
            </div>
        );
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <div className="counter-page pt_40 pb_70" style={loading ? (
                {background: '#a1a1a1'}
            ) : (
                {backgroundImage: 'url(/img/shape-1.png)'}
            )}>
                <div className="container">
                    <div className="row ov_hidden">
                        {loading ? (
                            <>
                                {this.counterPlaceholderItem()}
                                {this.counterPlaceholderItem()}
                                {this.counterPlaceholderItem()}
                                {this.counterPlaceholderItem()}
                            </>
                        ) : (
                            <>
                                {[1, 2, 3, 4].map(i => (
                                    this.counterItem(
                                        data["value"][`counter_${i}_icon`],
                                        data["value"][`counter_${i}_value`],
                                        data["value"][`counter_${i}_title`],
                                        i
                                    )

                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
