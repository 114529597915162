import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import Faq from "../components/section_elements/faq/Faq";
import api from "../utils/api";
import {getSetting, scrollToTop, setPageTitle} from "../utils/utils";
import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import Skeleton from "react-loading-skeleton";
import Slider from "../components/service_detail/Slider";
import LatestService from "../components/service_detail/LatestService";
import {withSettings} from "../utils/SettingsProvider";
import {Link} from "react-router-dom";
import ContactForm from "../components/ContactForm";

class ServiceDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {slug: prevSlug} = prevProps.match.params;
        const {slug: newSlug} = this.props.match.params;

        if (newSlug !== prevSlug) {
            this.fetchData(newSlug);
        }
    }

    componentDidMount() {
        const {slug} = this.props.match.params;
        this.fetchData(slug);
    }

    fetchData(slug) {
        api.get("/services/" + slug)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        // Scroll the document to the top
        scrollToTop();
    }

    render() {
        const {data, loading, error} = this.state;
        const {settings} = this.props;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading) {
            // Set Document Title
            setPageTitle("Service | " + data["service_name"]);
        }

        const contact_title = getSetting(settings, "SETTING_CONTACT_TITLE");
        const contact_phone = getSetting(settings, "SETTING_CONTACT_PHONE");
        const contact_email = getSetting(settings, "SETTING_CONTACT_EMAIL");
        const contact_address = getSetting(settings, "SETTING_CONTACT_ADDRESS");

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={loading ? "Service Detail" : data["service_name"]} banner={null}/>
                <div className="service-detail-area pt_40">
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-8 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="service-detail-text pt_30">
                                    {/* Slider */}
                                    {loading ? (
                                        <Skeleton width={"100%"} height={300}/>
                                    ) : (
                                        <Slider data={data["slider_images"]}/>
                                    )}

                                    {/* Service Detail */}
                                    {loading ? (
                                        <>
                                            <Skeleton width={"60%"} height={40} className="mb-4"/>
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"80%"}/>
                                            <Skeleton width={"96%"}/>
                                            <Skeleton width={"100%"} className="mt-5"/>
                                            <Skeleton width={"96%"}/>
                                            <Skeleton width={"100%"}/>
                                        </>
                                    ) : (
                                        <>
                                            <h2>{data["service_name"]}</h2>
                                            <div dangerouslySetInnerHTML={{__html: data["description"]}}/>
                                        </>
                                    )}
                                </div>

                                <div className="row ov_hidden wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="col-md-12">
                                        <div className="faq-service feature-section-text mt_50">
                                            <h2>Frequently Asked Question</h2>
                                            <Faq onlyFaq={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="service-sidebar">
                                    <div className="service-widget-contact mt-4">
                                        <h2>Contact Us</h2>
                                        <p>{contact_title}</p>
                                        <ul>
                                            <li><i className="fa fa-volume-control-phone"></i> {contact_phone}</li>
                                            <li><i className="fa fa-envelope"></i> {contact_email}</li>
                                            <li><i className="fa fa-map-marker"></i> {contact_address}</li>
                                        </ul>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </div>
                                    <div className="service-qucikcontact event-form mt_30">
                                        <h3>Quick Contact</h3>
                                        <ContactForm pageName={"service"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <LatestService/>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default withSettings(ServiceDetail);