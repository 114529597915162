import React from "react";
import Skeleton from "react-loading-skeleton";
import {AsyncImage} from "loadable-image";
import {Blur} from "transitions-kit";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE, SECTION_LOADING_BACKGROUND} from "../../../utils/constants";
import Media from "../../../utils/Media";
import {sectionElementBackground} from "../../../utils/utils";

export default class MoreCoInnovationCorner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/MORE_CO_INNOVATION_CORNER")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const imageUrl = loading ? null : Media.imageUrl(data["value"]["media"]);

        return (
            loading ? (
                <div className="dg-bgarea-2 mb_30" style={{background: SECTION_LOADING_BACKGROUND}}>
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                                <Skeleton width={450} height={400}/>
                            </div>
                            <div className="col-md-6">
                                <div className="dg-section-2 wow fadeInRight" data-wow-delay="0.2s">
                                    <h3>
                                        <Skeleton width={"100%"} height={20}/>
                                        <Skeleton width={"80%"} height={20}/>
                                        <Skeleton width={"90%"} height={20}/>
                                    </h3>

                                    <div className="mt-3">
                                        <Skeleton width={250} height={28} style={{borderRadius: "25px"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="dg-bgarea-2 mb_30" style={sectionElementBackground(data["value"], true)}>
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                                <AsyncImage
                                    src={imageUrl}
                                    Transition={Blur}
                                    style={{
                                        width: "450px",
                                        height: "auto",
                                        aspectRatio: 1
                                    }}/>
                            </div>
                            <div className="col-md-6">
                                <div className="dg-section-2 wow fadeInRight" data-wow-delay="0.2s">
                                    <h3>{data["value"]["title"]}</h3>
                                    <a href="#">More on Co-Innovation</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
