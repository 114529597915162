import React from "react";
import FaqItem from "./FaqItem";
import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import FaqPlaceholderItem from "./FaqPlaceholderItem";

export default class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
            activeFaq: null, // To store the ID of the active FAQ
        };
    }

    componentDidMount() {
        api.get("/faq")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;
        const {onlyFaq} = this.props;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            onlyFaq ? (
                <div>
                    {loading ? (
                        <>
                            <FaqPlaceholderItem/>
                            <FaqPlaceholderItem/>
                            <FaqPlaceholderItem/>
                        </>
                    ) : (
                        data.map((item, index) => (
                            <FaqItem data={item} index={index} key={index}/>
                        ))
                    )}
                </div>
            ) : (
                <div className="faq-area pt_20 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-12">
                                <div className="faq-service feature-section-text mt_50">
                                    <div className="feature-accordion" id="accordion">
                                        <div className="faq-area pt_20 pb_70">
                                            <div className="container">
                                                <div className="row ov_hidden">
                                                    <div className="col-md-12">
                                                        <div className="faq-service feature-section-text mt_50">
                                                            {loading ? (
                                                                <>
                                                                    <FaqPlaceholderItem/>
                                                                    <FaqPlaceholderItem/>
                                                                    <FaqPlaceholderItem/>
                                                                </>
                                                            ) : (
                                                                data.map((item, index) => (
                                                                    <FaqItem data={item} index={index} key={index}/>
                                                                ))
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}