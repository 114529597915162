import React, {Component} from "react";
import api from "../utils/api";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            errors: {}
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // Validation
        const errors = {};
        if (!this.state.name) {
            errors.name = 'Name is required';
        }

        if (!this.state.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
            errors.email = 'Invalid email address';
        }

        if (!this.state.phone) {
            errors.phone = 'Phone is required';
        }

        if (!this.state.subject) {
            errors.subject = 'Subject is required';
        }

        if (!this.state.message) {
            errors.message = 'Message is required';
        }
        // Add more validation rules for other fields if needed...

        if (Object.keys(errors).length === 0) {
            // No errors, submit the form
            api.post("/contact-form", {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                subject: this.state.subject,
                message: this.state.message
            })
                .then(response => {
                    // Handle success
                    console.log(response.data);
                    // Reset form fields
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        subject: '',
                        message: '',
                        errors: {}
                    });
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                });
        } else {
            // Errors found, update state with errors
            this.setState({ errors });
        }
    }

    render() {
        const { errors } = this.state;
        const {pageName} = this.props;

        if (pageName === 'contact-us') {
            return (
                <form onSubmit={this.handleSubmit}>
                    <div className="row contact-form">
                        <div className="col-lg-6 form-group">
                            <label>Name *</label>
                            <input type="text" className="form-control" name="name" value={this.state.name}
                                   onChange={this.handleChange} placeholder="Name"/>
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                        </div>
                        <div className="col-lg-6 form-group">
                            <label>Email Address *</label>
                            <input type="text" className="form-control" name="email" value={this.state.email}
                                   onChange={this.handleChange} placeholder="Email"/>
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <div className="col-lg-6 form-group">
                            <label>Phone Number *</label>
                            <input type="text" className="form-control" name="phone" value={this.state.phone}
                                   onChange={this.handleChange} placeholder="Phone"/>
                            {errors.phone && <div className="text-danger">{errors.phone}</div>}
                        </div>
                        <div className="col-lg-6 form-group">
                            <label>Subject *</label>
                            <input type="text" className="form-control" name="subject" value={this.state.subject}
                                   onChange={this.handleChange} placeholder="Subject"/>
                            {errors.subject && <div className="text-danger">{errors.subject}</div>}
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Message *</label>
                            <textarea name="message" className="form-control" value={this.state.message}
                                      onChange={this.handleChange} placeholder="Message"></textarea>
                            {errors.message && <div className="text-danger">{errors.message}</div>}
                        </div>
                        <div className="col-md-12 form-group">
                            <button type="submit" className="btn">Send Message</button>
                        </div>
                    </div>
                </form>
            );
        } else if (pageName === 'service') {
            return (
                <form onSubmit={this.handleSubmit}>
                    <div className="form-row row">
                        <div className="form-group col-md-12">
                            <input type="text" className="form-control" name="name" value={this.state.name}
                                   onChange={this.handleChange} placeholder="Name"/>
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                        </div>
                        <div className="form-group col-md-12">
                            <input type="text" className="form-control" name="email" value={this.state.email}
                                   onChange={this.handleChange} placeholder="Email"/>
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>
                        <div className="form-group col-md-12">
                            <input type="text" className="form-control" name="phone" value={this.state.phone}
                                   onChange={this.handleChange} placeholder="Phone"/>
                            {errors.phone && <div className="text-danger">{errors.phone}</div>}
                        </div>
                        <div className="form-group col-md-12">
                            <input type="text" className="form-control" name="subject" value={this.state.subject}
                                   onChange={this.handleChange} placeholder="Subject"/>
                            {errors.subject && <div className="text-danger">{errors.subject}</div>}
                        </div>
                        <div className="form-group col-md-12">
                            <textarea name="message" className="form-control" value={this.state.message}
                                      onChange={this.handleChange} placeholder="Message"></textarea>
                            {errors.message && <div className="text-danger">{errors.message}</div>}
                        </div>

                        <div className="form-group col-md-12">
                            <button type="submit" className="btn">Send Message</button>
                        </div>
                    </div>
                </form>
            )
        } else {
            return null;
        }
    }
}

export default ContactForm;