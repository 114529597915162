import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import {sectionElementValue, setPageTitle} from "../utils/utils";
import {API_DATA_NOT_AVAILABLE, SECTION_TEAM_POSTS_LIMIT} from "../utils/constants";
import api from "../utils/api";
import Error from "../components/Error";
import TeamPlaceholderItem from "../components/section_elements/team/TeamPlaceholderItem";
import TeamItem from "../components/section_elements/team/TeamItem";

class Team extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const limit = sectionElementValue(this.props, "limit") ?? SECTION_TEAM_POSTS_LIMIT;

        api.get("/teams", {
            params: {limit: limit}
        })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Teams");
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Our Team"} banner={null}/>
                <div className="team-page pt_40 pb_70">
                    <div className="container">
                        <div className="row">
                            {loading ? (
                                <>
                                    <TeamPlaceholderItem/>
                                    <TeamPlaceholderItem/>
                                    <TeamPlaceholderItem/>
                                </>
                            ) : (
                                data.map((item, index) => (
                                    <div key={index} className="col-lg-3 col-md-4 col-6 mt_30">
                                        <TeamItem data={item} isPage={true} />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default Team;