import React from "react";

import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import Footer from "../components/Footer";
import {setPageTitle} from "../utils/utils";

export default class NotFound extends React.Component {
    componentDidMount() {
        setPageTitle("Not Found")
    }

    render() {
        return (
            <>
                <TopNav/>
                <Menu/>
                <h1 className="py-5 text-center">Not Found</h1>
                <Footer/>
            </>
        )
    }
}