import React, {Component} from "react";
import Skeleton from "react-loading-skeleton";

class CareerPlaceholderItem extends Component {
    render() {
        return (
            <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="job-item">
                    <div className="job-item-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="job-img">
                                    <Skeleton width={"100px"} height={"100px"} circle={true}/>
                                </div>
                                <div className="job-wrapper-text">
                                    <Skeleton width={"100%"} height={"25px"}/>
                                    <Skeleton width={"85%"} className="mt-2"/>

                                    <Skeleton width={"100px"} className="mt-2"/>
                                    <Skeleton width={"100%"} className="mt-4"/>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="job-item-btn">
                                    <ul>
                                        <li><Skeleton width={"50px"} height={"25px"}/></li>
                                        <li className="ml-5"><Skeleton width={"90px"} height={"25px"}/></li>
                                        <li><Skeleton width={"90px"} height={"25px"}/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="job-item-keyword">
                        <Skeleton width={"100%"} height={"25px"}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default CareerPlaceholderItem;