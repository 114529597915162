import React, {Component} from "react";
import api from "../utils/api";
import {currency, filename, getTags, scrollToTop, setPageTitle} from "../utils/utils";
import Error from "../components/Error";
import Media from "../utils/Media";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";
import NotFound from "./NotFound";

class CareerDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const {slug} = this.props.match.params;
        this.fetchData(slug);
    }

    fetchData(slug) {
        api.get("/careers/" + slug)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        // Scroll the document to the top
        scrollToTop();
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data === null) {
            return <NotFound/>
        }

        if (!loading) {
            // Set Document Title
            setPageTitle("Career | " + data["title"]);
        }

        const imageUrl = loading ? null : Media.imageUrl(data["media"]);
        const tagsList = loading ? 0 : getTags(data["tags"]);

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={data["title"]} banner={null}/>
                <div className="career-detail-area bg-area pt_40 pb_70">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="Career-detail-text mt_30">

                                    {loading ? (
                                        <>
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"98%"}/>
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"80%"}/>

                                            <Skeleton width={"100%"} className={"mt-4"}/>
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"90%"}/>
                                        </>
                                    ) : (
                                        <div dangerouslySetInnerHTML={{__html: data["detail"]}}/>
                                    )}


                                    <div className="career-sidebar-list-btn">
                                        <ul>
                                            <li>
                                                {loading ? (
                                                    <Skeleton width={"200px"} height={"45px"}/>
                                                ) : (
                                                    <a href="#"><i className="fa fa-plus-circle"></i> &nbsp;Apply
                                                        NOw!</a>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="job-detail-keyword">
                                    {loading ? (
                                        <Skeleton width={"50%"} height={"30px"}/>
                                    ) : (
                                        <ul>
                                            <li><i className="fa fa-tags"></i>Keywords :</li>
                                            {tagsList.length > 0 && (
                                                tagsList.map((tag, index) => (
                                                    <li key={index}>
                                                        <Link to={""}><u>{tag.trim()}</u></Link>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="career-sidebar mt_30">
                                    <div className="career-sidebar-heading">
                                        <h4>Job Overview</h4>
                                    </div>
                                    <div className="career-sidebar-img">
                                        {loading ? (
                                            <Skeleton width={"100px"} height={"100px"} circle={true}/>
                                        ) : (
                                            <img src={imageUrl} alt={filename(imageUrl)}/>
                                        )}
                                    </div>

                                    <div className="career-sidebar-right-cont">
                                        <div className="career-sidebar-right-cont-wrapper">
                                            {loading ? (
                                                <div className="mt-4">
                                                    <Skeleton width={"90%"} height={"30px"}/>
                                                    <Skeleton width={"85%"} className="mt-3"/>
                                                </div>
                                            ) : (
                                                <>
                                                    <h4>{data["title"]}</h4>
                                                    <p>{data["company"]}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="career-sidebar-main-wrapper">
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-calendar"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Application Deadline:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["application_deadline"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-map-marker"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Location:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["location"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-info-circle"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Job Title:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["job_title"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-clock-o"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Hours:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["hours"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-money"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Salary:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["salary_from"] + " " + data["salary_to"] + currency()}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-th-large"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Category:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["category"]["category_name"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-graduation-cap"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Experience:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["experience"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="career-sidebar-wrapper2">
                                            <div className="career-sidebar-icon">
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <div className="career-sidebar-icon-con">
                                                <ul>
                                                    <li>Employment Type:</li>
                                                    <li>
                                                        {loading ? (
                                                            <Skeleton width={"100%"} height={"25px"}/>
                                                        ) : data["employment_type"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="career-sidebar-list-btn">
                                            <ul>
                                                <li>
                                                    {loading ? (
                                                        <Skeleton width={"100%"} height={"45px"}/>
                                                    ) : (
                                                        <a href="#"><i className="fa fa-plus-circle"></i> &nbsp;Apply
                                                            NOw!</a>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default CareerDetail;