import React from "react";
import {Link} from "react-router-dom";
import Media from "../../utils/Media";

export default class PageBanner extends React.Component {
    render() {
        const banner = this.props["banner"];
        const pageName = this.props["pageName"] ?? "Page Name";

        const backgroundUrl = banner ? Media.imageUrl(banner) : "/img/banner.jpg";

        return (
            <div className="banner-area flex" style={{backgroundImage: `url(${backgroundUrl})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-text">
                                <h1>{pageName}</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>{pageName}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}