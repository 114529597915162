import React from "react";
import SlickCarousel from "react-slick";
import Skeleton from "react-loading-skeleton";

import {makeSectionTitle, sectionElementValue} from "../../../utils/utils";
import {API_DATA_NOT_AVAILABLE, SECTION_TESTIMONIALS_POSTS_LIMIT} from "../../../utils/constants";
import api from "../../../utils/api";
import Error from "../../Error";
import ClientSayItem from "./ClientSayItem";
import ClientSayPlaceholderItem from "./ClientSayPlaceholderItem";

export default class ClientSay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const limit = sectionElementValue(this.props, "limit") ?? SECTION_TESTIMONIALS_POSTS_LIMIT;

        api.get("/testimonials", {
            params: {limit: limit}
        })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, withData, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const testimonialSettings = {
            dots: false,
            nav: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className="testimonial-area"
                 style={{background: (loading ? null : sectionElementValue(this.props, "background_color"))}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-headline left">
                                {loading ? (
                                    <h1>
                                        <Skeleton className="wow fadeInDown" data-wow-delay="0.2s" width={300}/>
                                    </h1>
                                ) : (
                                    <h1 className="wow fadeInDown"
                                        data-wow-delay="0.2s">{makeSectionTitle(
                                        sectionElementValue(this.props, "title") ?? withData["title"]
                                    )}</h1>
                                )}

                                {loading ? (
                                    <div className="wow fadeInDown" data-wow-delay="0.2s">
                                        <Skeleton width={"80%"} height={20}/>
                                        <Skeleton width={"70%"} height={20}/>
                                    </div>
                                ) : (
                                    <p className="wow fadeInDown" data-wow-delay="0.2s">{
                                        sectionElementValue(this.props, "subtitle") ?? withData["subtitle"]
                                    }</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ov_hidden">
                        <div className="col-lg-12 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="testimonial-texarea mt_30">
                                {loading ? (
                                    <ClientSayPlaceholderItem/>
                                ) : (
                                    <SlickCarousel {...testimonialSettings}>
                                        {data.map((item, index) => (
                                            <ClientSayItem data={item} key={index} />
                                        ))}
                                    </SlickCarousel>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
