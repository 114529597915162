import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import api from "../utils/api";
import Error from "../components/Error";
import Skeleton from "react-loading-skeleton";
import {AsyncImage} from "loadable-image";
import {convertTime24To12, filename, setPageTitle} from "../utils/utils";
import {Blur} from "transitions-kit";
import Media from "../utils/Media";
import {Link} from "react-router-dom";

class Event extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/events")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Events");
    }

    eventItem(data, index) {
        const imageUrl = Media.imageUrl(data["media"], 'medium');
        const event_date = new Date(data["event_date"]);

        return (
            <div key={index} className="col-lg-4 col-md-6 mt_30 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="event-item">
                    <div className="event-img">
                        <AsyncImage src={imageUrl}
                                    alt={filename(imageUrl)}
                                    Transition={Blur}
                                    style={{width: "100%", height: "auto", aspectRatio: 16 / 9}}/>
                        <div className="event-counter">
                            <ul id="clockdiv">
                                <li>
                                    <span>{event_date.getDate()}</span>
                                </li>
                                <li>
                                    <span>{event_date.toLocaleString('default', {month: 'long'})}</span>
                                </li>
                                <li>
                                    <span>{event_date.getFullYear()}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="event-text">
                        <h3><Link to={"/event/" + data["slug"]}>{data["event_name"]}</Link></h3>
                        <ul>
                            <li><i className="fa fa-clock-o"></i> {convertTime24To12(data["start_time"])} - {convertTime24To12(data["end_time"])}</li>
                            <li><i className="fa fa-map"></i> London, USA</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    eventPlaceholderItem() {
        return (
            <div className="col-lg-4 col-md-6 mt_30 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="event-item">
                    <div className="event-img">
                        <Skeleton width={"100%"} height={"200px"}/>

                        <div className="event-counter bg-light">
                            <Skeleton width={"100%"} height={"22px"}/>
                        </div>
                    </div>
                    <div className="event-text">
                        <p id="demo"></p>
                        <h3><Skeleton width={"100%"} height={"35px"}/></h3>
                        <ul>
                            <li><Skeleton width={"70%"}/></li>
                            <li><Skeleton width={"70%"}/></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Events"} banner={null}/>
                <div className="event-area bg-area pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            {loading ? (
                                <>
                                    {this.eventPlaceholderItem()}
                                    {this.eventPlaceholderItem()}
                                    {this.eventPlaceholderItem()}
                                </>
                            ) : (
                                data.map((item, index) => (
                                    this.eventItem(item, index)
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default Event;