import React from "react";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";

export default class FaqItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.index === 0,
        };
    }

    handleToggle = () => {
        this.setState((prevState) => ({
            open: !prevState.open,
        }));
    };

    render() {
        const {data, index} = this.props;
        const {open} = this.state;

        return (
            <div className="faq-item card wow fadeInLeft" data-wow-delay="0.2s">
                <div className="faq-header">
                    <button
                        className={open ? "faq-button" : "faq-button collapsed"}
                        onClick={this.handleToggle}
                        aria-expanded={open}
                        aria-controls={`collapse${index}`}>
                        {data["question"]}
                    </button>
                </div>
                <Collapse in={open}>
                    <div id={`collapse${index}`}>
                        <Card.Body dangerouslySetInnerHTML={{__html: data["answer"]}}/>
                    </div>
                </Collapse>
            </div>
        );
    }
}
