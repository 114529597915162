import React from "react";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";

export default class Tag extends React.Component {
    render() {
        const {data, loading} = this.props;

        return (
            <div className="sidebar-item tagcloud">
                <h3>Tags</h3>
                <div className="tagcloud">
                    {loading ? (
                        <div style={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                            <Skeleton width={"100px"} height={38}/>
                            <Skeleton width={"120px"} height={38}/>
                            <Skeleton width={"100px"} height={38}/>
                            <Skeleton width={"90px"} height={38}/>
                        </div>
                    ) : (
                        data.split(",").map((tag, index) => (
                            <span key={index}>
                                <Link to={"/blog?tags=" + tag}>{tag}</Link>
                            </span>
                        ))
                    )}
                </div>
            </div>
        );
    }
}