import React from "react";
import SlickCarousel from "react-slick";
import Skeleton from "react-loading-skeleton";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE, SECTION_TEAM_POSTS_LIMIT} from "../../../utils/constants";
import {makeSectionTitle, sectionElementValue} from "../../../utils/utils";
import TeamItem from "./TeamItem";
import TeamPlaceholderItem from "./TeamPlaceholderItem";

export default class Team extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const limit = sectionElementValue(this.props, "limit") ?? SECTION_TEAM_POSTS_LIMIT;

        api.get("/teams", {
            params: {limit: limit}
        })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, withData, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 9999,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
            ],
        };

        return (
            <div className="team-area"
                 style={{background: (loading ? null : sectionElementValue(this.props, "background_color"))}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-headline">
                                {loading ? (
                                    <h1>
                                        <Skeleton className="wow fadeInDown" data-wow-delay="0.2s" width={300}/>
                                    </h1>
                                ) : (
                                    <h1 className="wow fadeInDown"
                                        data-wow-delay="0.2s">{makeSectionTitle(
                                        sectionElementValue(this.props, "title") ?? withData["title"]
                                    )}</h1>
                                )}

                                {loading ? (
                                    <div className="wow fadeInDown" data-wow-delay="0.2s">
                                        <Skeleton width={"80%"} height={20}/>
                                        <Skeleton width={"70%"} height={20}/>
                                    </div>
                                ) : (
                                    <p className="wow fadeInDown" data-wow-delay="0.2s">{
                                        sectionElementValue(this.props, "subtitle") ?? withData["subtitle"]
                                    }</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ov_hidden">
                        <div className="col-md-12">
                            {loading ? (
                                <div className="row">
                                    <div className="col-md-3"><TeamPlaceholderItem/></div>
                                    <div className="col-md-3"><TeamPlaceholderItem/></div>
                                    <div className="col-md-3"><TeamPlaceholderItem/></div>
                                    <div className="col-md-3"><TeamPlaceholderItem/></div>
                                </div>
                            ) : (
                                <SlickCarousel className="team-carousel" {...settings}>
                                    {data.map((item, index) => (
                                        <TeamItem key={index} data={item}/>
                                    ))}
                                </SlickCarousel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
