import React from "react";
import Skeleton from "react-loading-skeleton";

export default class ServicePlaceholderItem extends React.Component {
    render() {
        return (
            <div className="col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="feature-item">
                    <div className="feature-icon">
                        <Skeleton circle={true} width={50} height={50}/>
                    </div>
                    <div className="feature-text">
                        <h4>
                            <Skeleton width={"100%"} height={30}/>
                        </h4>
                        <p>
                            <Skeleton width={"100%"} height={15}/>
                            <Skeleton width={"100%"} height={15}/>
                        </p>
                        <Skeleton width={100} height={18}/>
                    </div>
                </div>
            </div>
        );
    }
}
