import React from "react";
import Skeleton from "react-loading-skeleton";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import Media from "../../../utils/Media";
import {Blur} from "transitions-kit";
import {AsyncImage} from "loadable-image";

export default class AboutUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/ABOUT_US")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }


    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const imageUrl = loading ? null : Media.imageUrl(data["value"]["media"]);

        return (
            <div className="about-style1 pt_50 pb_110" style={{
                background: loading ? '' : data["value"]["background_color"]
            }}>
                <div className="container">
                    <div className="row ov_hidden">
                        <div className="col-lg-7 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="about1-text sm_pr_0 pr_150 mt_30">
                                {loading ? (
                                    <>
                                        <Skeleton width={"100%"} height={"42px"}/>

                                        <div className="mt-4">
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"95%"}/>
                                            <Skeleton width={"100%"}/>

                                            <div className="mt-4">
                                                <Skeleton width={"90%"}/>
                                                <Skeleton width={"100%"}/>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h1>{data["value"]["title"]}</h1>
                                        <div dangerouslySetInnerHTML={{__html: data["value"]["description"]}}/>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-5 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="about1-bgimg mt_30"
                                 style={{backgroundImage: 'url(/img/counter-photo.jpg)'}}>
                                <div className="about1-inner">
                                    <AsyncImage
                                        src={imageUrl}
                                        Transition={Blur}
                                        style={{
                                            width: "450px",
                                            height: "auto",
                                            aspectRatio: 1
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
