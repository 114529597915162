import React from "react";
import SlickCarousel from "react-slick";
import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import PartnerItem from "./PartnerItem";
import PartnerPlaceholderItem from "./PartnerPlaceholderItem";
import {sectionElementValue} from "../../../utils/utils";

export default class Partner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/PARTNER")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const settings = {
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            nav: false,
            responsive: [
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 9999,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
            ],
        };

        return (
            <div className="brand-area bg-area"
                 style={{background: (loading ? null : sectionElementValue(this.props, "background_color"))}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 wow fadeInDown" data-wow-delay="0.2s">
                            {loading ? (
                                <>
                                    <PartnerPlaceholderItem/>
                                    <PartnerPlaceholderItem/>
                                    <PartnerPlaceholderItem/>
                                    <PartnerPlaceholderItem/>
                                </>
                            ) : (
                                <SlickCarousel {...settings}>
                                    {data["value"].map((item, index) => (
                                        <PartnerItem data={item} key={index}/>
                                    ))}
                                </SlickCarousel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
