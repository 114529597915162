import React from "react";
import Skeleton from "react-loading-skeleton";

export default class TeamPlaceholderItem extends React.Component {
    render() {
        return (
            <div className="wow fadeInLeft mt-4" data-wow-delay="0.2s">
                <div className="team-photo">
                    <Skeleton width={"100%"} height={300}/>
                </div>
                <div className="mt-3">
                    <Skeleton width={"100%"} height={30}/>
                    <Skeleton width={"100%"} height={20}/>
                </div>
            </div>
        )
    }
}