import React, {Component} from "react";
import api from "../utils/api";
import {scrollToTop, setPageTitle} from "../utils/utils";
import Error from "../components/Error";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import NotFound from "./NotFound";
import Loading from "../components/Loading";
import Description from "../components/case_study/Description";

class CaseStudyDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const {slug} = this.props.match.params;
        this.fetchData(slug);
    }

    fetchData(slug) {
        api.get("/case-studies/" + slug)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        // Scroll the document to the top
        scrollToTop();
    }

    renderedSections(sections) {
        return sections.map((item, index) => {
            if (item["section_name"] === "description") {
                    return <Description sectionElements={item["section_elements"]} key={index}/>;
            } else {
                return null;
            }
        })
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data === null) {
            return <NotFound/>
        }

        if (!loading) {
            // Set Document Title
            setPageTitle("Case Study | " + data["title"]);
        }

        console.log(data);

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={data["title"]} banner={null}/>
                {loading ? <Loading/> : (
                    this.renderedSections(data["sections"])
                )}
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default CaseStudyDetail;