import React, {Component} from "react";
import api from "../../utils/api";
import Error from "../Error";
import {API_DATA_NOT_AVAILABLE} from "../../utils/constants";
import Skeleton from "react-loading-skeleton";

class Category extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/career-categories")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <ul>
                {loading ? (
                    <>
                        <Skeleton width={"100%"} height={"40px"} className="mb-3"/>
                        <Skeleton width={"100%"} height={"40px"} className="mb-3"/>
                        <Skeleton width={"100%"} height={"40px"} className="mb-3"/>
                    </>
                ) : (
                    data.map((item, index) => (
                        <li key={index}><a href=""><i
                            className="fa fa-chevron-right"></i> {item["category_name"]} ({item["careers_count"]})</a>
                        </li>
                    ))
                )}
            </ul>
        );
    }
}

export default Category;