import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import api from "../utils/api";
import Error from "../components/Error";
import ServicePlaceholderItem from "../components/service/ServicePlaceholderItem";
import ServiceItem from "../components/service/ServiceItem";
import {setPageTitle} from "../utils/utils";

class Service extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/services")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Services");
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Services"} banner={null}/>
                <div className="service-area-2 bg-area pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            {loading ? (
                                <>
                                    <ServicePlaceholderItem/>
                                    <ServicePlaceholderItem/>
                                    <ServicePlaceholderItem/>
                                    <ServicePlaceholderItem/>
                                    <ServicePlaceholderItem/>
                                    <ServicePlaceholderItem/>
                                </>
                            ) : (
                                data.map((item, index) => (
                                    <ServiceItem key={index} data={item}/>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default Service;