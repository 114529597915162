import React from "react";
import {withRouter} from "react-router-dom";

import api from "../utils/api";
import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import Slider from "../components/section_elements/slider/Slider";
import Strategy from "../components/section_elements/strategy/Strategy";
import Service from "../components/section_elements/service/Service";
import Blog from "../components/section_elements/blog/Blog";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Team from "../components/section_elements/team/Team";
import ContactForm from "../components/section_elements/contact_form/ContactForm";
import CoInnovationCorner from "../components/section_elements/co_innovation_corner/CoInnovationCorner";
import DigitalTransformation from "../components/section_elements/digital_transformation/DigitalTransformation";
import MoreCoInnovationCorner from "../components/section_elements/more_co_innovation_corner/MoreCoInnovationCorner";
import ClientSay from "../components/section_elements/client_say/ClientSay";
import Partner from "../components/section_elements/partner/Partner";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import Footer from "../components/Footer";
import Description from "../components/section_elements/description/Description";
import NotFound from "./NotFound";
import Faq from "../components/section_elements/faq/Faq";
import Loading from "../components/Loading";
import PageBanner from "../components/section_elements/PageBanner";
import {setPageTitle} from "../utils/utils";
import ImageAndText from "../components/Image_and_text/ImageAndText";
import PhotoGallery from "../components/gallery/PhotoGallery";
import VideoGallery from "../components/gallery/VideoGallery";

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const pageName = this.props.match.params["page_name"];

        api.get(`/pages/${pageName}`)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle(pageName.toUpperCase());
    }

    renderedSections(pageSections) {
        return pageSections.map((item, index) => {
            switch (item["section_name"]) {
                case "slider":
                    return <Slider sectionElements={item["section_elements"]} key={index}/>;
                case "strategy":
                    return <Strategy sectionElements={item["section_elements"]} key={index}/>;
                case "service":
                    return <Service sectionElements={item["section_elements"]} key={index}/>;
                case "blog":
                    return <Blog sectionElements={item["section_elements"]} key={index}/>;
                case "newsletter":
                    return <Newsletter sectionElements={item["section_elements"]} key={index}/>;
                case "team":
                    return <Team sectionElements={item["section_elements"]} key={index}/>;
                case "contact_form":
                    return <ContactForm sectionElements={item["section_elements"]} key={index}/>;
                case "co_innovation_corner":
                    return <CoInnovationCorner sectionElements={item["section_elements"]} key={index}/>;
                case "more_co_innovation_corner":
                    return <MoreCoInnovationCorner sectionElements={item["section_elements"]} key={index}/>;
                case "digital_transformation":
                    return <DigitalTransformation sectionElements={item["section_elements"]} key={index}/>;
                case "client_say":
                    return <ClientSay sectionElements={item["section_elements"]} key={index}/>;
                case "partner":
                    return <Partner sectionElements={item["section_elements"]} key={index}/>;
                case "description":
                    return <Description sectionElements={item["section_elements"]} key={index}/>;
                case "faq":
                    return <Faq sectionElements={item["section_elements"]} key={index}/>;
                case "image_and_text":
                    return <ImageAndText sectionElements={item["section_elements"]} key={index}/>;
                case "image_gallery":
                    return <PhotoGallery {...this.props} sectionElements={item["section_elements"]} key={index}/>;
                case "video_gallery":
                    return <VideoGallery {...this.props} sectionElements={item["section_elements"]} key={index}/>;
                default:
                    return null;
            }
        })
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data === null) {
            return <NotFound/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                {loading ? <Loading/> : (
                    <>
                        <PageBanner banner={data["media"]} pageName={data["page_name"]}/>
                        {this.renderedSections(data["page_sections"])}
                    </>
                )}
                <Footer/>
            </>

        );
    }
}

export default withRouter(Page);