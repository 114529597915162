import React from "react";

export default class Loading extends React.Component {
    render() {
        return (
            <div style={{
                position: "absolute",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}>
                <img src={"/loading.gif"} width="80" height="80" alt="Loading..."/>
                <p className="mt-2">Please wait...</p>
            </div>
        );
    }
}