import React from "react";
import api from "./api";

import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "./constants";
import {getSetting} from "./utils";
import config from "../config/config";

const SettingsContext = React.createContext(undefined);

class SettingsProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/settings")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (data) {
            // Website Favicon Add
            const favicon = getSetting(data, 'SETTING_SITE_FAVICON');
            const faviconElement = document.querySelector('link[rel="icon"]');

            faviconElement.href = config.backendUrl + '/' + favicon;
        }

        return (
            <SettingsContext.Provider value={data}>
                {this.props.children}
            </SettingsContext.Provider>
        );
    }
}

const withSettings = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            return (
                <SettingsContext.Consumer>
                    {(settings) => <WrappedComponent {...this.props} settings={settings}/>}
                </SettingsContext.Consumer>
            );
        }
    };
};

export {SettingsProvider, withSettings};
