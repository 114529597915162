import React, {Component} from "react";
import Media from "../../utils/Media";

class PhotoGalleryItem extends Component {
    render() {
        const {data} = this.props;
        const imageUrl = data["media"] ? Media.imageUrl(data["media"]) : data["media_url"];

        return (
            <div className="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="2, 3"
                 data-sort="Menu">
                <div className="gallery-photo hover-item">
                    <img src={imageUrl} alt="Gallery Photo"/>
                    <div className="gallery-icon">
                        <a href={imageUrl} className="magnific"><i
                            className="fa fa-search"></i></a>
                    </div>
                    <div className="ser-hover">
                        <span className="hover1"></span>
                        <span className="hover2"></span>
                        <span className="hover3"></span>
                        <span className="hover4"></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default PhotoGalleryItem;