import React from "react";
import {makeSectionTitle, sectionElementValue} from "../../utils/utils";

export default class Description extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="py-5" style={{background: sectionElementValue(this.props, "background_color")}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-headline">
                                <h1 className="wow fadeInDown" data-wow-delay="0.2s">
                                    {makeSectionTitle(sectionElementValue(this.props, "title"))}
                                </h1>

                                <p className="wow fadeInDown" data-wow-delay="0.2s">
                                    {sectionElementValue(this.props, "subtitle")}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 wow fadeInDown" data-wow-delay="0.2s">
                            <div className="mt-5" dangerouslySetInnerHTML={{__html: sectionElementValue(this.props, "description")}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}