import React from "react";
import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE, SECTION_LOADING_BACKGROUND} from "../../../utils/constants";
import Media from "../../../utils/Media";
import {Blur} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import Skeleton from "react-loading-skeleton";
import {sectionElementBackground} from "../../../utils/utils";

export default class DigitalTransformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/DIGITAL_TRANSFORMATION")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const imageUrl = loading ? null : Media.imageUrl(data["value"]["media"]);

        return (
            loading ? (
                <div className="dg-bgarea mt_70" style={{background: SECTION_LOADING_BACKGROUND}}>
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-12">
                                <div className="dg-section wow fadeInRight bg-transparent" data-wow-delay="0.2s">
                                    <Skeleton width={"100%"} height={180}/>

                                    <div className="mt-4">
                                        <Skeleton width={"100%"} height={20}/>
                                        <Skeleton width={"90%"} height={20}/>
                                    </div>

                                    <div className="mt-4">
                                        <Skeleton width={"100%"} height={15}/>
                                        <Skeleton width={"100%"} height={15}/>
                                        <Skeleton width={"100%"} height={15}/>
                                        <Skeleton width={"100%"} height={15}/>
                                        <Skeleton width={"100%"} height={15}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="dg-bgarea mt_70" style={sectionElementBackground(data["value"], true)}>
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-12">
                                <div className="dg-section wow fadeInRight" data-wow-delay="0.2s">
                                    <AsyncImage
                                        src={imageUrl}
                                        Transition={Blur}
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            aspectRatio: 2
                                        }}/>
                                    <h2>{data["value"]["title"]}</h2>
                                    <p>{data["value"]["subtitle"]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
