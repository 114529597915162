import Media from "./Media";
import config from "../config/config";

/**
 *
 * @param string
 * @returns {JSX.Element}
 */
export const makeSectionTitle = (string) => {
    if (!string) {
        return null;
    }

    const regex = /`([^`]+)`/g;
    const replacedString = string.replace(regex, '<span>$1</span>');

    return <div dangerouslySetInnerHTML={{__html: replacedString}}/>;
};

/**
 *
 * @param url
 * @returns {string}
 */
export const filename = (url) => {
    const pathname = new URL(url).pathname;
    return pathname.split("/").pop();
}

/**
 *
 * @param string
 * @param maxLength
 * @returns {*|string}
 */
export const textMore = (string, maxLength = Infinity) => {
    if (string.length <= maxLength) {
        return string;
    } else {
        const stringCut = string.substring(0, maxLength);
        const endPoint = stringCut.lastIndexOf(' ');

        return (endPoint ? stringCut.substring(0, endPoint) : stringCut.substring(0)) + "...";
    }
}

/**
 * For component's [Strategy,Service, Blog, ...] attribute 'sectionElements'
 * @param props
 * @param property
 * @returns {*|null}
 */
export const sectionElementValue = (props, property) => {
    if (props.hasOwnProperty("sectionElements")) {
        return props["sectionElements"][property] ?? null;
    } else {
        return null;
    }
}

/**
 *
 * @param value
 * @param css
 * @returns {{background: string, backgroundSize: string, backgroundRepeat: string}|{background}|*|string}
 */
export const sectionElementBackground = (value, css = false) => {
    if (value["background_media_id"].length === 36) {
        const backgroundUrl = Media.imageUrl(value["background_media"]);

        if (css === false) {
            return backgroundUrl;
        } else {
            return {
                background: `url(${backgroundUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }
        }
    } else {
        if (css === false) {
            return value["background_media_id"];
        } else {
            return {
                background: value["background_media_id"]
            }
        }
    }
}

/**
 *
 * @param settings
 * @param settingName
 * @returns {*|null|undefined}
 */
export const getSetting = (settings, settingName) => {
    if (settings.length) {
        const setting = settings.find(item => item["setting_name"] === settingName);
        return setting ? setting["value"] : null;
    }

    return undefined;
};

/**
 *
 * @param uri
 * @returns {string}
 */
export const backendUrl = (uri = null) => {
    return config.backendUrl + uri;
}

/**
 *
 * @param title
 */
export const setPageTitle = (title) => {
    document.title = title;
}

/**
 *
 */
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling behavior
    });
}

/**
 *
 * @param name
 * @returns {string}
 */
export const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name)
}

export const getYoutubeVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
}

export const getYoutubeVideoThumbnail = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
}

export const currency = () => {
    return "USD";
}

export const getTags = (tagsString) => {
    return tagsString.split(",");
}


export const convertTime24To12 = (time24) => {
    let [hours, minutes, seconds] = time24.split(':').map(Number);

    const meridiem = hours < 12 ? 'AM' : 'PM';
    hours = (hours % 12) || 12;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${meridiem}`;
}