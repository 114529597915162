import React from "react";
import BlogItem from "./BlogItem";
import Skeleton from "react-loading-skeleton";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE, SECTION_BLOG_POSTS_LIMIT} from "../../../utils/constants";
import {makeSectionTitle, sectionElementValue} from "../../../utils/utils";
import BlogPlaceholderItem from "./BlogPlaceholderItem";

export default class Blog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const limit = sectionElementValue(this.props, "limit") ?? SECTION_BLOG_POSTS_LIMIT;

        api.get("/blogs", {
            params: {limit: limit}
        })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, withData, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <div className="blog-area blog-home"
                 style={{background: (loading ? null : sectionElementValue(this.props, "background_color"))}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-headline">
                                {loading ? (
                                    <h1>
                                        <Skeleton className="wow fadeInDown" data-wow-delay="0.2s" width={300}/>
                                    </h1>
                                ) : (
                                    <h1 className="wow fadeInDown"
                                        data-wow-delay="0.2s">{makeSectionTitle(withData["title"])}</h1>
                                )}

                                {loading ? (
                                    <div className="wow fadeInDown" data-wow-delay="0.2s">
                                        <Skeleton width={"80%"} height={20}/>
                                        <Skeleton width={"70%"} height={20}/>
                                    </div>
                                ) : (
                                    <p className="wow fadeInDown" data-wow-delay="0.2s">{withData["subtitle"]}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ov_hidden">
                        <div className="col-md-12">
                            {loading ? (
                                <>
                                    <BlogPlaceholderItem index={0}/>
                                    <BlogPlaceholderItem index={1}/>
                                    <BlogPlaceholderItem index={2}/>
                                </>
                            ) : (
                                data.map((item, index) => (
                                    <BlogItem key={index} data={item} index={index}/>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
