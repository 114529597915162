import React from "react";
import BaseSlider from "react-slick";
import Skeleton from "react-loading-skeleton";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import api from "../../../utils/api";
import SlideItem from "./SlideItem";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";

export default class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/sliders")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;
        // const {sectionElements} = this.props;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        const settings = {
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplaySpeed: 1500,
            smartSpeed: 1500,
            margin: 0,
            animateIn: "fadeIn",
            animateOut: "fadeOut",
            dots: true,
            nav: false,
            navText: ["<i class='fa fa-caret-left'></i>", "<i class='fa fa-caret-right'></i>"],
            responsive: []
        };


        return (
            <div className="slider">
                {error && <Error message={error}/>}

                <div className="slide-carousel">
                    {loading ? (
                        <Skeleton
                            width={"100%"}
                            height={600}
                        />
                    ) : (
                        <BaseSlider {...settings}>
                            {data.map((item, index) => (
                                <SlideItem
                                    key={index}
                                    data={item}
                                />
                            ))}
                        </BaseSlider>
                    )}
                </div>
            </div>
        );
    }
}
