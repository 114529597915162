import React from "react";

class Comment extends React.Component {
    render() {
        return (
            <>
                <div className="comment-list mt_30">
                    <h4>Comments <span className="c-number">(4)</span></h4>
                    <ul>
                        <li>
                            <div className="comment-item">
                                <div className="thumb">
                                    <img src="/img/c1.jpg" alt=""/>
                                </div>
                                <div className="com-text">
                                    <h5>Danny Ashton</h5>
                                    <span className="date"><i className="fa fa-calendar"></i> 25 October, 2020</span>
                                    <span className="reply"><a href="#">Reply</a></span>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        Debitis aperiam est ex optio magnam? Aliquid itaque libero eius
                                        labore fugiat atque, amet totam enim.
                                    </p>
                                </div>
                            </div>
                            <ul className="children">
                                <li>
                                    <div className="comment-item">
                                        <div className="thumb">
                                            <img src="/img/c2.jpg" alt=""/>
                                        </div>
                                        <div className="com-text">
                                            <h5>Brent Grundy</h5>
                                            <span className="date"><i
                                                className="fa fa-calendar"></i> 25 October, 2020</span>
                                            <span className="reply"><a href="#">Reply</a></span>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit. Debitis aperiam est ex optio magnam? Aliquid
                                                itaque libero eius labore fugiat atque, amet totam enim.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className="comment-item">
                                <div className="thumb">
                                    <img src="/img/c2.jpg" alt=""/>
                                </div>
                                <div className="com-text">
                                    <h5>Jeff Benson</h5>
                                    <span className="date"><i className="fa fa-calendar"></i> 25 October, 2020</span>
                                    <span className="reply"><a href="#">Reply</a></span>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        Debitis aperiam est ex optio magnam? Aliquid itaque libero eius
                                        labore fugiat atque, amet totam enim.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="comment-item">
                                <div className="thumb">
                                    <img src="/img/c1.jpg" alt=""/>
                                </div>
                                <div className="com-text">
                                    <h5>Dabbi Ashton</h5>
                                    <span className="date"><i className="fa fa-calendar"></i> 25 October, 2020</span>
                                    <span className="reply"><a href="#">Reply</a></span>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        Debitis aperiam est ex optio magnam? Aliquid itaque libero eius
                                        labore fugiat atque, amet totam enim.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="comment-form mt_30">
                    <h4>Get a Comment</h4>
                    <form>
                        <div className="form-row row">
                            <div className="form-group col-md-12">
                                <input type="text" className="form-control" placeholder="Name"/>
                            </div>
                            <div className="form-group col-md-12">
                                <input type="text" className="form-control"
                                       placeholder="Email Address"/>
                            </div>
                            <div className="form-group col-md-12">
                                <input type="text" className="form-control" placeholder="Phone Number"/>
                            </div>
                            <div className="form-group col-12">
                                <textarea className="form-control" placeholder="Comment"></textarea>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Captcha *</label>
                                <div className="cap-row">
                                    <div className="cap-number pt_10">14 + 4 =</div>
                                    <input type="text" className="form-control"/>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <button type="submit" className="btn">Submit Comment</button>
                            </div>

                        </div>
                    </form>
                </div>
            </>
        );
    }
}

export default Comment;