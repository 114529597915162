import React, {Component} from "react";
import {filename, sectionElementValue} from "../../utils/utils";
import {Link} from "react-router-dom";
import {AsyncImage} from "loadable-image";
import {Blur} from "transitions-kit";
import Media from "../../utils/Media";

class ImageAndText extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const imageUrl = Media.imageUrl(sectionElementValue(this.props, "media"));
        const imagePosition = sectionElementValue(this.props, "image_position");

        return (
            <div className="skill-area pt_60 pb_60" style={{background: sectionElementValue(this.props, "background_color")}}>
                <div className="container">
                    <div className="row mt_30 ov_hidden">
                        {imagePosition === 'Left' ? (
                            <>
                                <div className="col-md-6 pr_0 xs_pr_15 wow fadeInLeft" data-wow-delay="0.2s">
                                    <div className="skill_pro_item">
                                        <div className="skill_pro_photo">
                                            <AsyncImage src={imageUrl}
                                                        alt={filename(imageUrl)}
                                                        Transition={Blur}
                                                        style={{width: "100%", height: "auto", aspectRatio: 16 / 9}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pl_0 xs_pl_15 wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="text-table">
                                        <div className="skill_pro_text">
                                            <div className="mt-5"
                                                 dangerouslySetInnerHTML={{__html: sectionElementValue(this.props, "description")}}/>
                                            <div className="newsl-button mt_25">
                                                <Link to={sectionElementValue(this.props, "read_more_url")}>Read
                                                    more</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-md-6 pl_0 xs_pl_15 wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="text-table">
                                        <div className="skill_pro_text">
                                            <div className="mt-5"
                                                 dangerouslySetInnerHTML={{__html: sectionElementValue(this.props, "description")}}/>
                                            <div className="newsl-button mt_25">
                                                {sectionElementValue(this.props, "read_more_url") && <Link to={sectionElementValue(this.props, "read_more_url")}>Read
                                                    more</Link>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pr_0 xs_pr_15 wow fadeInLeft" data-wow-delay="0.2s">
                                    <div className="skill_pro_item">
                                        <div className="skill_pro_photo">
                                            <AsyncImage src={imageUrl}
                                                        alt={filename(imageUrl)}
                                                        Transition={Blur}
                                                        style={{width: "100%", height: "auto", aspectRatio: 16 / 9}}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}

export default ImageAndText;