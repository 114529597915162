import React, {Component} from "react";
import api from "../utils/api";
import Error from "../components/Error";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import Footer from "../components/Footer";
import PageBanner from "../components/section_elements/PageBanner";
import Partner from "../components/section_elements/partner/Partner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import {AsyncImage} from "loadable-image";
import Media from "../utils/Media";
import {filename, scrollToTop, setPageTitle} from "../utils/utils";
import {Blur} from "transitions-kit";
import Skeleton from "react-loading-skeleton";
import Comment from "../components/blog_detail/Comment";
import Tag from "../components/blog_detail/Tag";
import Category from "../components/blog_detail/Category";
import RecentPost from "../components/blog_detail/RecentPost";
import NotFound from "./NotFound";

class BlogDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {slug: prevSlug} = prevProps.match.params;
        const {slug: newSlug} = this.props.match.params;

        if (newSlug !== prevSlug) {
            this.fetchData(newSlug);
        }
    }

    componentDidMount() {
        const {slug} = this.props.match.params;
        this.fetchData(slug);
    }

    fetchData(slug) {
        api.get("/blogs/" + slug)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        // Scroll the document to the top
        scrollToTop();
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data === null) {
            return <NotFound/>
        }

        if (!loading) {
            // Set Document Title
            setPageTitle("Blog | " + data["title"]);
        }

        const imageUrl = loading ? null : Media.imageUrl(data["media"]);

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={data["title"]} banner={null}/>
                <div className="blog-page single-blog pt_40 pb_90">
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-lg-9 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="blog-item">
                                    <div className="single-blog-image">
                                        {loading ? (
                                            <Skeleton width={"100%"} height={"400px"}/>
                                        ) : (
                                            <AsyncImage src={imageUrl}
                                                        alt={filename(imageUrl)}
                                                        Transition={Blur}
                                                        style={{width: "100%", height: "auto", aspectRatio: 16 / 9}}/>
                                        )}

                                        {loading ? (
                                            <div className="blog-author border-0 bg-light">
                                                <span><Skeleton width={"100px"} height={"20px"}/></span>
                                                <span><Skeleton width={"100px"} height={"20px"}/></span>
                                                <span><Skeleton width={"100px"} height={"20px"}/></span>
                                            </div>
                                        ) : (
                                            <div className="blog-author">
                                                <span><i className="fa fa-user"></i> {data["posted_by"]}</span>
                                                <span><i className="fa fa-calendar-o"></i> {data["posted_on"]}</span>
                                                <span><i className="fa fa-list"
                                                         aria-hidden="true"></i> {data["category"]["category_name"]}</span>
                                            </div>
                                        )}

                                    </div>
                                    <div className="blog-text pt_40">
                                        {loading ? (
                                            <>
                                                <Skeleton width={"70%"} height={40}/>

                                                <Skeleton width={"100%"} height={20}/>
                                                <Skeleton width={"100%"} height={20}/>
                                                <Skeleton width={"100%"} height={20}/>
                                                <Skeleton width={"100%"} height={20}/>
                                            </>
                                        ) : (
                                            <>
                                                <h3>{data["title"]}</h3>
                                                <div dangerouslySetInnerHTML={{__html: data["detail"]}}/>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <Comment/>
                            </div>
                            <div className="col-lg-3 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="sidebar">
                                    <Category selected={!loading && data["category"]["slug"]}/>
                                    <RecentPost/>
                                    <Tag loading={loading} data={data["tags"]}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default BlogDetail;