import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import PhotoGalleryMain from "../components/gallery/PhotoGallery";

class PhotoGallery extends Component {
    render() {
        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Photo Gallery"} banner={null}/>

                <PhotoGalleryMain {...this.props}/>

                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default PhotoGallery;