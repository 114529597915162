import React from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Footer from "../components/Footer";
import FaqComponent from "../components/section_elements/faq/Faq";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import {setPageTitle} from "../utils/utils";

export default class Faq extends React.Component {
    componentDidMount() {
        setPageTitle("FAQs");
    }

    render() {
        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"FAQ"} banner={null}/>
                <FaqComponent />
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}