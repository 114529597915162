import React from "react";
import Skeleton from "react-loading-skeleton";

import ServiceItem from "./ServiceItem";
import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE, SECTION_SERVICE_POSTS_LIMIT} from "../../../utils/constants";
import ServicePlaceholderItem from "./ServicePlaceholderItem";
import {makeSectionTitle, sectionElementValue} from "../../../utils/utils";
import {Link} from "react-router-dom";

export default class OurService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        const limit = sectionElementValue(this.props, "limit") ?? SECTION_SERVICE_POSTS_LIMIT;

        api.get("/services", {
            params: {limit: limit}
        })
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, withData, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <div className="service-area bg-area"
                 style={{background: (loading ? null : sectionElementValue(this.props, "background_color"))}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-headline">
                                {loading ? (
                                    <h1>
                                        <Skeleton className="wow fadeInDown" data-wow-delay="0.2s" width={300}/>
                                    </h1>
                                ) : (
                                    <h1 className="wow fadeInDown"
                                        data-wow-delay="0.2s">{makeSectionTitle(
                                        sectionElementValue(this.props, "title") ?? withData["title"]
                                    )}</h1>
                                )}

                                {loading ? (
                                    <div className="wow fadeInDown" data-wow-delay="0.2s">
                                        <Skeleton width={"80%"} height={20}/>
                                        <Skeleton width={"70%"} height={20}/>
                                    </div>
                                ) : (
                                    <p className="wow fadeInDown" data-wow-delay="0.2s">{
                                        sectionElementValue(this.props, "subtitle") ?? withData["subtitle"]
                                    }</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ov_hidden">
                        {loading ? (
                            <>
                                <ServicePlaceholderItem/>
                                <ServicePlaceholderItem/>
                                <ServicePlaceholderItem/>
                                <ServicePlaceholderItem/>
                                <ServicePlaceholderItem/>
                                <ServicePlaceholderItem/>
                            </>
                        ) : (
                            data.map((item, index) => (
                                <ServiceItem key={index} data={item}/>
                            ))
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {loading ? (
                                <div className="home-button ser-btn mt_40 wow fadeInUp" data-wow-delay="0.2s">
                                    <Skeleton width={216} height={57}/>
                                </div>
                            ) : (
                                <div className="home-button ser-btn mt_40 wow fadeInUp" data-wow-delay="0.2s">
                                    <Link to="/service">View All Service</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
