import config from "../config/config";

class Media {
    constructor() {
        this.baseUrl = config.backendUrl;
        // this.baseUrl = "http://192.168.0.179:8000";
    }

    getImageName(media, size) {
        return media["data"]["filename"]["images"][size];
    }

    getDirectory(media) {
        return media["directory"];
    }

    getExtension(media) {
        return media["extension"]
    }

    getTitle(media) {
        return media["title"];
    }

    getSubtitle(media) {
        return media["subtitle"];
    }

    /**
     *
     * @param media
     * @param size  original|medium|small
     * @returns {string}
     */
    imageUrl(media, size = "original") {
        return `${this.baseUrl}/storage/${this.getDirectory(media)}/${this.getImageName(media, size)}`;
    }
}

export default new Media();
