import React, {Component} from 'react';
import {setPageTitle} from "../../utils/utils";
import {GALLERY_PAGINATION_DATA_LIMIT} from "../../utils/constants";
import api from "../../utils/api";
import Error from "../Error";
import InfiniteScroll from "react-infinite-scroll-component";
import PhotoGalleryPlaceholderItem from "./PhotoGalleryPlaceholderItem";
import PhotoGalleryItem from "./PhotoGalleryItem";
import Category from "./Category";
import {Link} from "react-router-dom";

class PhotoGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            error: null,
            currentPage: 1,
            hasMore: true,
        };
    }

    componentDidMount() {
        this.fetchMoreData();
        setPageTitle("Photo Gallery");
    }

    fetchMoreData = (category = null) => {
        let {currentPage} = this.state;

        // Reset currentPage to 1 if category is not null
        if (category !== null) {
            currentPage = 1;
        }

        const {category_name} = this.props.match.params;
        if (category_name) {
            category = category_name;
        }


        const limit = GALLERY_PAGINATION_DATA_LIMIT;
        const offset = (currentPage - 1) * limit;
        const type = "Photo Gallery";

        this.setState({
            loading: true,
            error: null
        });

        api.get("/gallery", {
            params: {
                limit,
                offset,
                type,
                category,
            }
        })
            .then((response) => {
                const newData = response.data["data"];

                this.setState((prevState) => ({
                    data: [...prevState.data, ...newData],
                    loading: false,
                    currentPage: prevState.currentPage + 1,
                    hasMore: !!newData.length,
                }));
            })
            .catch((error) => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {category_name: prev_category_name} = prevProps.match.params;
        const {category_name: new_category_name} = this.props.match.params;

        if (prev_category_name !== new_category_name) {
            this.setState({
                data: [],
                currentPage: 1,
            });

            this.fetchMoreData(new_category_name);
        }
    }

    render() {
        const {data, error, hasMore} = this.state;

        if (error) {
            return <Error message={error}/>;
        }

        return (
            <div className="gallery-area pt_70 pb_70">
                <div className="container">
                    <Category/>

                    <InfiniteScroll
                        className={"row ov_hidden filtr-container wow fadeInUp"}
                        dataLength={data.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <>
                                <PhotoGalleryPlaceholderItem/>
                                <PhotoGalleryPlaceholderItem/>
                                <PhotoGalleryPlaceholderItem/>
                            </>
                        }>
                        {data.map((item, index) => (
                            <PhotoGalleryItem key={index} data={item}/>
                        ))}
                    </InfiniteScroll>

                    {this.props.sectionElements !== undefined && <div className="text-center">
                        <Link to={"/photo-gallery"}
                              className="btn btn-secondary btn-lg rounded-0 px-5 mt-5">More...</Link>
                    </div>}

                </div>
            </div>
        );
    }
}

export default PhotoGallery;