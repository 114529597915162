import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import api from "../utils/api";
import {scrollToTop, setPageTitle} from "../utils/utils";
import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import Skeleton from "react-loading-skeleton";
import {Blur} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import Media from "../utils/Media";
import SocialLink from "../components/section_elements/team/SocialLink";

class TeamDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
            option: 'education',
        };
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        this.fetchData(id);
    }

    fetchData(slug) {
        api.get("/teams/" + slug)
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        // Scroll the document to the top
        scrollToTop();
    }

    handleInformation(option) {
        this.setState({
            option: option,
        })
    }

    render() {
        const {data, loading, error, option} = this.state;


        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading) {
            console.log(data);
            // Set Document Title
            setPageTitle("Team Member | " + data["first_name"] + ' ' + data["last_name"]);
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Our Team"} banner={null}/>
                <div className="team-detail-page pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-lg-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="team-detail-photo">
                                    {loading ? (
                                        <Skeleton width={"100%"} height={350}/>
                                    ) : (
                                        <AsyncImage src={Media.imageUrl(data["media"])}
                                                    Transition={Blur}
                                                    style={{width: "100%", height: "auto", aspectRatio: 0.88}}/>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-8 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="team-detail-text">
                                    {loading ? (
                                        <>
                                            <h4><Skeleton width={250} height={30}/></h4>
                                            <Skeleton width={200} height={20}/>

                                            <div className="mt-3">
                                                <Skeleton width={"100%"} height={15}/>
                                                <Skeleton width={"90%"} height={15}/>
                                                <Skeleton width={"95%"} height={15}/>
                                                <Skeleton width={"95%"} height={15}/>
                                            </div>

                                            <ul>
                                                <li><Skeleton width={35} height={35}/></li>
                                                <li><Skeleton width={35} height={35}/></li>
                                                <li><Skeleton width={35} height={35}/></li>
                                                <li><Skeleton width={35} height={35}/></li>
                                                <li><Skeleton width={35} height={35}/></li>
                                            </ul>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{data["first_name"] + ' ' + data["last_name"]}</h4>
                                            <span><b>{data["designation"]}</b></span>

                                            <div dangerouslySetInnerHTML={{__html: data["detail"]}}/>

                                            <SocialLink data={data["social_urls"]} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="team-exp-area bg-area pt_70 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            <div className="col-md-12">
                                <div className="team-headline wow fadeInDown" data-wow-delay="0.2s">
                                    <h2>My Information</h2>
                                </div>
                            </div>

                            {loading ? (
                                <div className="col-md-12">
                                    <Skeleton width={"100%"} height={240} className="mt-4"/>
                                </div>
                            ) : (
                                <div className="col-md-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="event-detail-tab mt_50">
                                        <ul className="nav nav-tabs">
                                            <li className="active">
                                                <button onClick={() => this.handleInformation('education')}
                                                        className={option === 'education' ? 'active' : null}>My
                                                    Education
                                                </button>
                                            </li>
                                            <li>
                                                <button onClick={() => this.handleInformation('skill')}
                                                        className={option === 'skill' ? 'active' : null}
                                                        data-toggle="tab">My Skill
                                                </button>
                                            </li>
                                            <li>
                                                <button onClick={() => this.handleInformation('experience')}
                                                        className={option === 'experience' ? 'active' : null}
                                                        data-toggle="tab">My Experience
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content event-detail-content">
                                        <div
                                            className={option === 'education' ? 'tab-pane fade show active' : 'tab-pane fade'}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div dangerouslySetInnerHTML={{__html: data["education"]}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={option === 'skill' ? 'tab-pane fade show active' : 'tab-pane fade'}>
                                            <div className="progress-gallery main-prog">
                                                {data["skills"].map((item, index) => (
                                                    <div key={index} className="bar-container">
                                                        <p>{item["name"]}</p>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar progress-bar-success progress-bar-custom"
                                                                role="progressbar" aria-valuenow={item["level"]} aria-valuemin="0"
                                                                aria-valuemax="100" style={{width: `${item["level"]}%`}}>
                                                            </div>
                                                        </div>
                                                        <div className="percentage-show">{item["level"]}%</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div
                                            className={option === 'experience' ? 'tab-pane fade show active' : 'tab-pane fade'}>
                                            <div className="row">
                                                {data["work_experiences"].map((item, index) => (
                                                    <div key={index} className="col-lg-4 col-md-6">
                                                        <div className="expreaness-item mt_30">
                                                            <div className="expreaness-colume">
                                                                <i className={item["icon"] ?? "fa fa-cubes"}></i>
                                                                <div className="expreaness-text">
                                                                    <h4>{item["title"]}</h4>
                                                                    <p>{item["content"]}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default TeamDetail;