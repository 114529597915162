import React from "react";
import api from "../../utils/api";
import Error from "../Error";
import {API_DATA_NOT_AVAILABLE} from "../../utils/constants";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";

export default class Category extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/blog-categories")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    categoryItem(data, index, selected) {
        return <li key={index} className={data["slug"] === selected ? "active" : ""}>
            <Link to={"/blog?category=" + data["category_name"]}>
                <i className="fa fa-chevron-right"></i> {data["category_name"]}
            </Link>
        </li>
    }

    categoryPlaceholderItem() {
        return <Skeleton width={"100%"} height={"50px"}/>
    }

    render() {
        const {data, loading, error} = this.state;
        const {selected} = this.props;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <div className="sidebar-item">
                <h3>Categories</h3>
                <ul>
                    {loading ? (
                        <>
                            {this.categoryPlaceholderItem()}
                            {this.categoryPlaceholderItem()}
                            {this.categoryPlaceholderItem()}
                            {this.categoryPlaceholderItem()}
                        </>
                    ) : (
                        data.map((item, index) => (
                            this.categoryItem(item, index, selected)
                        ))
                    )}
                </ul>
            </div>
        );
    }
}