import React from "react";
import Skeleton from "react-loading-skeleton";

export default class PartnerPlaceholderItem extends React.Component {
    render() {
        return (
            <div className="col-3 float-left">
                <Skeleton width={"100%"} height={125}/>
            </div>
        );
    }
}
