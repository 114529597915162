import React from "react";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import {AsyncImage} from "loadable-image"
import {Blur} from "transitions-kit"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css"

import api from "../../../utils/api";
import Media from "../../../utils/Media";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import {sectionElementValue} from "../../../utils/utils";

export default class MyComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/STRATEGY")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const settings = {
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            margin: 30,
            dots: false,
            nav: false,
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            responsive: []
        };

        return (
            <div className="strategy-area pt_30 mt_50"
                 style={{background: (loading ? null : sectionElementValue(this.props, "background_color"))}}>
                <div className="container">
                    <div className="row">
                        {loading ? (
                            <div className="col-lg-2 col-sm-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="progress-img">
                                    <Skeleton
                                        width={160}
                                        height={160}
                                        circle={true}
                                    />
                                    <span style={{margin: 0}}>
                                        <Skeleton
                                            width={"100%"}
                                            height={25}
                                            style={{marginTop: "-30px"}}
                                        />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-2 col-sm-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="progress-img">
                                    <AsyncImage src={Media.imageUrl(data["value"]["data"][0]["media"])}
                                                Transition={Blur}
                                                style={{width: "160px", height: "160px", borderRadius: "50%"}}/>
                                    <span>{data["value"]["data"][0]["title"]}</span>
                                </div>
                            </div>
                        )}

                        {loading ? (
                            <div className="col-lg-2 col-sm-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="progress-img mt_80">
                                    <Skeleton
                                        width={160}
                                        height={160}
                                        circle={true}
                                    />
                                    <span style={{margin: 0}}>
                                        <Skeleton
                                            width={"100%"}
                                            height={25}
                                            style={{marginTop: "-30px"}}
                                        />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-2 col-sm-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="progress-img mt_80">
                                    <AsyncImage src={Media.imageUrl(data["value"]["data"][1]["media"])}
                                                Transition={Blur}
                                                style={{width: "160px", height: "160px", borderRadius: "50%"}}/>
                                    <span>{data["value"]["data"][1]["title"]}</span>
                                </div>
                            </div>
                        )}

                        {loading ? (
                            <div className="col-lg-2 col-sm-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="progress-img">
                                    <Skeleton
                                        width={160}
                                        height={160}
                                        circle={true}
                                    />
                                    <span style={{margin: 0}}>
                                        <Skeleton
                                            width={"100%"}
                                            height={25}
                                            style={{marginTop: "-30px"}}
                                        />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-2 col-sm-4 wow fadeInLeft" data-wow-delay="0.2s">
                                <div className="progress-img">
                                    <AsyncImage src={Media.imageUrl(data["value"]["data"][2]["media"])}
                                                Transition={Blur}
                                                style={{width: "160px", height: "160px", borderRadius: "50%"}}/>
                                    <span>{data["value"]["data"][2]["title"]}</span>
                                </div>
                            </div>
                        )}


                        <div className="col-lg-6 col-sm-12 margin-left-auto wow fadeInRight" data-wow-delay="0.2s">
                            <div className="project-img">
                                {loading ? (
                                    <Skeleton
                                        width={"350px"}
                                        height={"350px"}
                                        circle={true}
                                        style={{marginTop: "-52px"}}/>
                                ) : (
                                    <Slider {...settings}>
                                        <div>
                                            <AsyncImage src={Media.imageUrl(data["value"]["data"][3]["media"])}
                                                        Transition={Blur}
                                                        style={{width: "350px", height: "350px", borderRadius: "50%"}}/>
                                        </div>

                                        <div>
                                            <AsyncImage src={Media.imageUrl(data["value"]["data"][4]["media"])}
                                                        Transition={Blur}
                                                        style={{width: "350px", height: "350px", borderRadius: "50%"}}/>
                                        </div>
                                    </Slider>
                                )}

                                {loading ? (
                                    <span>
                                        <Skeleton
                                            width={"100%"}
                                            height={25}
                                            style={{marginTop: "-32px"}}
                                        />
                                    </span>
                                ) : (
                                    <span>{data["value"]["data"][3]["title"] ?? data["value"]["data"][4]["title"]}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
