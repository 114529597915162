import React, {Component} from "react";
import ReactSlick from "react-slick";
import Media from "../../utils/Media";

class Slider extends Component {
    render() {
        const settings = {
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplaySpeed: 1500,
            smartSpeed: 1500,
            margin: 0,
            animateIn: "fadeIn",
            animateOut: "fadeOut",
            dots: true,
            nav: false,
            navText: ["<i class='fa fa-caret-left'></i>", "<i class='fa fa-caret-right'></i>"],
            responsive: []
        };

        const {data} = this.props;

        return (
            <div className="row mb_30">
                <div className="col-md-12">
                    <div className="swiper-container pro-detail-top">
                        <div className="swiper-wrapper">
                            <ReactSlick {...settings}>
                                {data.map((item, index) => (
                                    <div key={index} className="swiper-slide">
                                        <div className="catagory-item">
                                            <div className="catagory-img-holder">
                                                <img src={Media.imageUrl(item)} alt=""/>
                                                <div className="catagory-text">
                                                    <div className="catagory-text-table">
                                                        <div className="catagory-text-cell">
                                                            <ul className="catagory-hover">
                                                                <li><a href="/img/services3.jpg"
                                                                       className="magnific"><i
                                                                    className="fa fa-search"></i></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </ReactSlick>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;