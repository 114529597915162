import React from "react";
import Media from "../../../utils/Media";
import {Link} from "react-router-dom";
import {AsyncImage} from "loadable-image";
import {Blur} from "transitions-kit";
import Skeleton from "react-loading-skeleton";
import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import {makeSectionTitle} from "../../../utils/utils";

export default class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
            withData: [],
        };
    }

    componentDidMount() {
        api.get("/case-studies")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }


    caseStudyItem(data, index) {
        const imageUrl = Media.imageUrl(data["media"]);

        return (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="pop-service-item">
                    <Link to={"/case-studies/" + data["slug"]}>
                        <AsyncImage src={imageUrl}
                                    Transition={Blur}
                                    style={{width: "100%", height: "auto", aspectRatio: 1.0}}/>
                        <div className="pop-service-text flex">
                            <span className="up-arrow"></span>
                            <p>{data["title"]}</p>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }

    caseStudyPlaceholderItem() {
        return (
            <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
                <Skeleton width={"100%"} height={"200px"}/>
            </div>
        );
    }

    render() {
        const {data, withData, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <div className="pop-servicearea">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-headline">
                                {loading ? (
                                    <h1>
                                        <Skeleton className="wow fadeInDown" data-wow-delay="0.2s" width={300}/>
                                    </h1>
                                ) : (
                                    <h1 className="wow fadeInDown"
                                        data-wow-delay="0.2s">{makeSectionTitle(withData["title"])}</h1>
                                )}

                                {loading ? (
                                    <div className="wow fadeInDown" data-wow-delay="0.2s">
                                        <Skeleton width={"80%"} height={20}/>
                                        <Skeleton width={"70%"} height={20}/>
                                    </div>
                                ) : (
                                    <p className="wow fadeInDown" data-wow-delay="0.2s">{withData["subtitle"]}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ov_hidden mt_30">
                        {loading ? (
                            <>
                                {this.caseStudyPlaceholderItem()}
                                {this.caseStudyPlaceholderItem()}
                                {this.caseStudyPlaceholderItem()}
                                {this.caseStudyPlaceholderItem()}
                            </>
                        ) : (
                            data.map((item, index) => (
                                this.caseStudyItem(item, index)
                            ))
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
