import React, {Component} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import Footer from "../components/Footer";
import PageBanner from "../components/section_elements/PageBanner";
import BlogItem from "../components/blog/BlogItem";
import {API_DATA_NOT_AVAILABLE, BLOG_PAGINATION_DATA_LIMIT} from "../utils/constants";
import api from "../utils/api";
import Error from "../components/Error";
import BlogPlaceholderItem from "../components/blog/BlogPlaceholderItem";
import {getQueryParam, setPageTitle} from "../utils/utils";
import {withRouter} from "react-router-dom";

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            error: null,
            currentPage: 1,
            hasMore: true,
        };
    }

    componentDidMount() {
        this.fetchMoreData();
        setPageTitle("Blogs");
    }

    fetchMoreData = () => {
        const {currentPage} = this.state;
        const limit = BLOG_PAGINATION_DATA_LIMIT;
        const offset = (currentPage - 1) * limit;

        // When Search
        const category = getQueryParam("category");
        const tags = getQueryParam("tags");

        this.setState({loading: true, error: null});

        api.get("/blogs", {
            params: {
                limit,
                offset,
                category,
                tags,
            }
        })
            .then((response) => {
                const newData = response.data["data"];

                this.setState((prevState) => ({
                    data: [...prevState.data, ...newData],
                    loading: false,
                    currentPage: prevState.currentPage + 1,
                    hasMore: !!newData.length,
                }));
            })
            .catch((error) => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error, hasMore} = this.state;

        if (error) {
            return <Error message={error}/>;
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>;
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Blog"} banner={null}/>
                <div className="pt_40 pb_90">
                    <div className="container">
                        <div className="ov_hidden">
                            <InfiniteScroll
                                className="row"
                                dataLength={data.length}
                                next={this.fetchMoreData}
                                hasMore={hasMore}
                                loader={
                                    <>
                                        <BlogPlaceholderItem/>
                                        <BlogPlaceholderItem/>
                                        <BlogPlaceholderItem/>
                                    </>
                                }>
                                {data.map((item, index) => (
                                    <BlogItem key={index} data={item}/>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default withRouter(Blog);