import React from "react";
import Skeleton from "react-loading-skeleton";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import {makeSectionTitle, sectionElementBackground} from "../../../utils/utils";

export default class Newsletter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
            email: '',
            emailMessage: '',
        };
    }

    componentDidMount() {
        api.get("/section-elements/NEWSLETTER")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    handleChange = (event) => {
        this.setState({email: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const email = this.state.email;

        if (!email) {
            this.setState({
                emailMessage: 'Email address is required',
            });
        } else if (!this.validateEmail(email)) {
            this.setState({
                emailMessage: 'Invalid you email address',
            });
        } else {
            api.post("/subscribe", {
                email: email
            })
                .then(response => {
                    this.setState({
                        emailMessage: response.data["message"],
                        loading: false,
                        email: '',
                    });
                })
                .catch(error => {
                    this.setState({
                        emailMessage: error.toString(),
                        loading: false,
                    });
                });
        }
    }

    render() {
        const {data, loading, error, email, emailMessage} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        return (
            <div className={loading ? "bg-light pt-5 pb-5" : "subscribe-area"}
                 style={loading ? null : sectionElementBackground(data["value"], true)}>
                <div className="container">
                    <div className="row ov_hd">
                        <div className="col-md-12 wow fadeInDown" data-wow-delay="0.2s">
                            <div className="main-headline white">
                                {loading ? (
                                    <div className="pt-5">
                                        <h1><Skeleton width={"100%"}/></h1>
                                        <p>
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"90%"}/>
                                            <Skeleton width={"80%"}/>
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <h1>{makeSectionTitle(data["value"]["title"])}</h1>
                                        <p>{data["value"]["subtitle"]}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ov_hd">
                        <div className="col-md-12 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="subscribe-form">
                                {loading ? (
                                    <Skeleton width={"100%"} height={50} className="mb-5"
                                              style={{borderRadius: "20px"}}/>
                                ) : (
                                    <form onSubmit={this.handleSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Write Your Email Here"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                        <button type="submit" className="btn-sub">Subscribe</button>
                                    </form>
                                )}
                            </div>

                            <p className="text-center text-white mt-4">{emailMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
