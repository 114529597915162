import React from "react";
import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import Skeleton from "react-loading-skeleton";
import {sectionElementBackground} from "../../../utils/utils";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/CONTACT_FORM")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const background = loading ? {} : sectionElementBackground(data["value"], true);

        return (
            <div className={loading ? "bg-light pt-5 pb-5" : "dg-bgarea"}
                 style={{minHeight: "400px", ...background}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ov_hidden">
                            {loading ? (
                                <Skeleton width={415} height={450}/>
                            ) : (
                                data["value"]["visibility"] === "on" ? (
                                    <div className="dg-form wow fadeInLeft" data-wow-delay="0.2s">
                                        <form>
                                            <div className="row contact-form">
                                                <div className="col-lg-6 form-group">
                                                    <input type="text" className="form-control" id="name"
                                                           placeholder="Name"/>
                                                </div>
                                                <div className="col-lg-6 form-group">
                                                    <input type="text" id="email" className="form-control"
                                                           placeholder="Email"/>
                                                </div>
                                                <div className="col-lg-6 form-group">
                                                    <input type="text" id="phone" className="form-control"
                                                           placeholder="Phone"/>
                                                </div>
                                                <div className="col-lg-6 form-group">
                                                    <input type="text" id="subject" className="form-control"
                                                           placeholder="Subject"/>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                            <textarea name="message" className="form-control" id="message"
                                                      placeholder="Message"/>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Captcha *</label>
                                                    <div className="cap-row">
                                                        <div className="cap-number pt_10">14 + 4 =</div>
                                                        <input type="text" className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <button type="submit" className="btn">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) : 'aa'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
