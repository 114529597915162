import React from "react";
import {withSettings} from "../../utils/SettingsProvider";
import {getSetting} from "../../utils/utils";
import {SECTION_LOADING_BACKGROUND} from "../../utils/constants";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";

class TopNav extends React.Component {
    render() {
        const {settings} = this.props;
        const facebook = getSetting(settings, "SETTING_SOCIAL_FACEBOOK");
        const instagram = getSetting(settings, "SETTING_SOCIAL_INSTAGRAM");
        const linkedin = getSetting(settings, "SETTING_SOCIAL_LINKEDIN");
        const twitter = getSetting(settings, "SETTING_SOCIAL_TWITTER");
        const youtube = getSetting(settings, "SETTING_SOCIAL_YOUTUBE");

        const phone = getSetting(settings, "SETTING_CONTACT_PHONE");
        const email = getSetting(settings, "SETTING_CONTACT_EMAIL");

        return (
            settings.length ? (
                <div className="header-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-5 col-12">
                                <div className="header-social">
                                    <ul>
                                        <li>
                                            <div className="social-bar">
                                                <ul>
                                                    <li><a href={facebook} target="_blank" className="text-white"><i className="fa fa-facebook"/></a></li>
                                                    <li><a href={instagram} target="_blank" className="text-white"><i className="fa fa-instagram"/></a></li>
                                                    <li><a href={linkedin} target="_blank" className="text-white"><i className="fa fa-linkedin"/></a></li>
                                                    <li><a href={twitter} target="_blank" className="text-white"><i className="fa fa-twitter"/></a></li>
                                                    <li><a href={youtube} target="_blank" className="text-white"><i className="fa fa-youtube"/></a></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7 col-12">
                                <div className="header-info">
                                    <ul>
                                        <li>
                                            <i className="fa fa-envelope"/>
                                            <span>{email}</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"/>
                                            <span>{phone}</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-lock"/>
                                            <Link to={""}>Login</Link> / <Link to={""}>Register</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="header-area" style={{background: SECTION_LOADING_BACKGROUND}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-5 col-12">
                                <div className="header-social">
                                    <ul>
                                        <li>
                                            <div className="social-bar">
                                                <ul>
                                                    <li><Skeleton width={25} height={25}/></li>
                                                    <li><Skeleton width={25} height={25}/></li>
                                                    <li><Skeleton width={25} height={25}/></li>
                                                    <li><Skeleton width={25} height={25}/></li>
                                                    <li><Skeleton width={25} height={25}/></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7 col-12">
                                <ul className="float-right">
                                    <li className="float-left ml-4"><Skeleton width={150} height={25}/></li>
                                    <li className="float-left ml-4"><Skeleton width={150} height={25}/></li>
                                    <li className="float-left ml-4"><Skeleton width={150} height={25}/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}


export default withSettings(TopNav);