import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Footer from "../components/Footer";
import Partner from "../components/section_elements/partner/Partner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import CareerItem from "../components/career/CareerItem";
import Category from "../components/career/Category";
import api from "../utils/api";
import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import CareerPlaceholderItem from "../components/career/CareerPlaceholderItem";
import {setPageTitle} from "../utils/utils";

class Career extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/careers")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Careers");
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Career"} banner={null}/>
                <div className="job-area bg-area pt_40 pb_70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="row ov_hidden">
                                    {loading ? (
                                        <>
                                            <CareerPlaceholderItem/>
                                            <CareerPlaceholderItem/>
                                        </>
                                    ) : (
                                        data.map((item, index) => (
                                            <CareerItem key={index} data={item}/>
                                        ))
                                    )}

                                </div>
                            </div>
                            <div className="col-lg-3 wow fadeInRight" data-wow-delay="0.2s">
                                <div className="service-sidebar pt_30">
                                    <div className="service-widget">
                                        <Category/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default Career;