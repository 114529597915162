import React from "react";
import Skeleton from "react-loading-skeleton";

import api from "../../../utils/api";
import Error from "../../Error";
import {API_DATA_NOT_AVAILABLE} from "../../../utils/constants";
import Media from "../../../utils/Media";
import {Blur} from "transitions-kit";
import {AsyncImage} from "loadable-image";

export default class Mission extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/section-elements/MISSION")
            .then(response => {
                this.setState({
                    data: response.data["data"] ?? [],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }


    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        if (!loading && data["status"] === 0) {
            return false;
        }

        const imageUrl = loading ? null : Media.imageUrl(data["value"]["media"]);

        return (
            <div className="mission-area pt_50 pb_110" style={{
                background: loading ? '' : data["value"]["background_color"]
            }}>
                <div className="container">
                    <div className="row ov_hidden">
                        <div className="col-md-6 pt_30 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="mission-img">
                                <AsyncImage
                                    src={imageUrl}
                                    Transition={Blur}
                                    style={{
                                        width: "520px",
                                        height: "auto",
                                        aspectRatio: 1
                                    }}/>
                            </div>
                        </div>
                        <div className="col-md-6 pt_30 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="mission-text">
                                {loading ? (
                                    <>
                                        <Skeleton width={"100%"} height={"42px"}/>

                                        <div className="mt-4">
                                            <Skeleton width={"100%"}/>
                                            <Skeleton width={"95%"}/>
                                            <Skeleton width={"100%"}/>

                                            <div className="mt-4">
                                                <Skeleton width={"90%"}/>
                                                <Skeleton width={"100%"}/>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h1>{data["value"]["title"]}</h1>
                                        <div dangerouslySetInnerHTML={{__html: data["value"]["description"]}}/>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
