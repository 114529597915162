import React from "react";
import Media from "../../../utils/Media";

export default class SlideItem extends React.Component {
    render() {
        const {data} = this.props;
        const image = Media.imageUrl(data["media"]);

        return (
            <div className="slider-item flex" style={{backgroundImage: `url(${image})`}}>
                <div className="bg-slider"/>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="slider-text left">
                                <div className="text-animated">
                                    <h1>{data["title"]}</h1>
                                </div>
                                <div className="text-animated">
                                    <p>{data["subtitle"]}</p>
                                </div>
                                <div className="text-animated">
                                    <ul>
                                        {data["button1_text"] ?
                                            <li><a className="hvr-shutter-in-horizontal" href={data["button1_url"]}>{data["button1_text"]}</a>
                                            </li> : ""}

                                        {data["button2_text"] ?
                                            <li className="btn-red"><a className="hvr-shutter-in-horizontal" href={data["button2_url"]}>{data["button2_text"]}</a>
                                            </li> : ""}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
