import React, {Component} from "react";
import TestimonialItem from "../components/testimonial/TestimonialItem";
import TestimonialPlaceholderItem from "../components/testimonial/TestimonialPlaceholderItem";
import {
    API_DATA_NOT_AVAILABLE,
    TESTIMONIAL_PAGINATION_DATA_LIMIT,
} from "../utils/constants";
import api from "../utils/api";
import Error from "../components/Error";
import InfiniteScroll from "react-infinite-scroll-component";
import {setPageTitle} from "../utils/utils";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";

class Testimonial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            error: null,
            currentPage: 1,
            hasMore: true,
        };
    }

    componentDidMount() {
        this.fetchMoreData();
        setPageTitle("Testimonial");
    }

    fetchMoreData = () => {
        const { currentPage } = this.state;
        const limit = TESTIMONIAL_PAGINATION_DATA_LIMIT;
        const offset = (currentPage - 1) * limit;

        this.setState({loading: true, error: null});

        api.get("/testimonials", { params: { limit, offset } })
            .then((response) => {
                const newData = response.data["data"];

                this.setState((prevState) => ({
                    data: [...prevState.data, ...newData],
                    loading: false,
                    currentPage: prevState.currentPage + 1,
                    hasMore: !!newData.length,
                }));
            })
            .catch((error) => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    render() {
        const {data, loading, error, hasMore} = this.state;

        if (error) {
            return <Error message={error}/>;
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>;
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Testimonial"} banner={null}/>
                <div className="testimonial-page pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            <InfiniteScroll
                                className="row"
                                dataLength={data.length}
                                next={this.fetchMoreData}
                                hasMore={hasMore}
                                loader={
                                    <>
                                        <TestimonialPlaceholderItem/>
                                        <TestimonialPlaceholderItem/>
                                        <TestimonialPlaceholderItem/>
                                    </>
                                }>
                                {data.map((item, index) => (
                                    <TestimonialItem key={index} data={item}/>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default Testimonial;