import React from "react";
import Media from "../../../utils/Media";
import {AsyncImage} from "loadable-image";
import {Blur} from "transitions-kit";

export default class PartnerItem extends React.Component {
    render() {
        const {data} = this.props;

        const imageUrl = Media.imageUrl(data["media"]);

        return (
            <div>
                <div className="brand-item mx-3" title={data["title"]}>
                    <div className="brand-colume">
                        <div className="brand-bg"/>
                        <AsyncImage src={imageUrl}
                                    Transition={Blur}
                                    style={{
                                        width: "200",
                                        height: "auto",
                                        aspectRatio: 21/9
                                    }}/>
                    </div>
                </div>
            </div>
        );
    }
}
