import React, {Component} from "react";
import {setPageTitle} from "../../utils/utils";
import {API_DATA_NOT_AVAILABLE} from "../../utils/constants";
import api from "../../utils/api";
import Error from "../Error";
import Skeleton from "react-loading-skeleton";
import {withRouter} from "react-router-dom";

class Category extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            withData: [],
            loading: true,
            error: null,
            active: null,
        };
    }

    componentDidMount() {
        this.fetchMoreData();
        setPageTitle("Testimonial");
    }

    handleActiveCategory(category_name) {
        this.setState({
            active: category_name
        });

        const url = this.props.match.url;
        const parts = url.split('/');
        const firstPart = parts[1];

        if (category_name) {
            this.props.history.push(`/${firstPart}/` + category_name);
        } else {
            this.props.history.push(`/${firstPart}`);
        }
    }

    fetchMoreData = () => {
        api.get("/gallery-categories")
            .then((response) => {
                this.setState(() => ({
                    data: response.data["data"],
                    withData: response.data["with"],
                    loading: false,
                }));
            })
            .catch((error) => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });
    }

    categoryItem(data, index, active) {
        return <li
            key={index}
            onClick={() => this.handleActiveCategory(data["category_name"])}
            className={active === data["category_name"] ? "filtr filtr-active" : "filtr"}>{data["category_name"]}</li>
    }

    categoryPlaceholderItem() {
        return <Skeleton width={150} height={40} className="mx-1"/>
    }

    render() {
        const {data, loading, error, active} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <div className="row ov_hidden">
                <div className="col-md-12 wow fadeInDown" data-wow-delay="0.2s">
                    <div className="portfolio-menu">
                            {loading ? (
                                <div className="d-flex justify-content-centers">
                                    {this.categoryPlaceholderItem()}
                                    {this.categoryPlaceholderItem()}
                                    {this.categoryPlaceholderItem()}
                                </div>
                            ) : (
                                <ul id="filtrnav">
                                    <li
                                        onClick={() => this.handleActiveCategory(null)}
                                        className={active === null ? "filtr filtr-active" : "filtr"}>All
                                    </li>
                                    {data.map((item, index) => (
                                        this.categoryItem(item, index, active)
                                    ))}
                                </ul>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Category);