import React from "react";

import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import Slider from "../components/section_elements/slider/Slider";
import Strategy from "../components/section_elements/strategy/Strategy";
import Service from "../components/section_elements/service/Service";
import ContactForm from "../components/section_elements/contact_form/ContactForm";
import CoInnovationCorner from "../components/section_elements/co_innovation_corner/CoInnovationCorner";
import CaseStudy from "../components/section_elements/case_study/CaseStudy";
import DigitalTransformation from "../components/section_elements/digital_transformation/DigitalTransformation";
import ClientSay from "../components/section_elements/client_say/ClientSay";
import MoreCoInnovationCorner from "../components/section_elements/more_co_innovation_corner/MoreCoInnovationCorner";
import Team from "../components/section_elements/team/Team";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Blog from "../components/section_elements/blog/Blog";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import {getSetting, setPageTitle} from "../utils/utils";
import {withSettings} from "../utils/SettingsProvider";

class Home extends React.Component {
    componentDidMount() {
        const {settings} = this.props;
        const title = getSetting(settings, "SETTING_SITE_TITLE");

        settings.length ? setPageTitle(title) : setPageTitle("Home");
    }

    render() {
        return (
            <>
                <TopNav/>
                <Menu/>
                <Slider/>
                <Strategy/>
                <Service/>
                <ContactForm/>
                <CoInnovationCorner/>
                <CaseStudy/>
                <DigitalTransformation/>
                <ClientSay/>
                <MoreCoInnovationCorner/>
                <Team/>
                <Newsletter/>
                <Blog/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default withSettings(Home);