import React from "react";
import Skeleton from "react-loading-skeleton";

export default class ClientSayPlaceholderItem extends React.Component {
    render() {
        return (
            <div style={{background: "#ffffff", padding: "97px 80px 80px 80px", position: "relative"}}>
                <Skeleton width={"40px"} height={"30px"} style={{position: "absolute", marginTop: "-20px"}}/>

                <Skeleton width={"100%"} height={15}/>
                <Skeleton width={"85%"} height={15}/>
                <Skeleton width={"95%"} height={15}/>

                <div className="testi-info" style={{padding: 0, display: "flex", alignItems: "center"}}>
                    <Skeleton width={80} height={80} circle={true}/>

                    <div className="ml-4">
                        <h4>
                            <Skeleton width={"200px"} height={20}/>
                        </h4>
                        <Skeleton width={"160px"} height={15}/>
                    </div>
                </div>
            </div>
        );
    }
}
