import React from "react";
import Skeleton from "react-loading-skeleton";

export default class FaqPlaceholderItem extends React.Component {
    render() {
        return (
            <div className="wow fadeInLeft my-4" data-wow-delay="0.2s">
                <Skeleton width={"100%"} height={50}/>
            </div>
        );
    }
}
