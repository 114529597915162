import React, {Component} from "react";
import TopNav from "../components/menu/TopNav";
import Menu from "../components/menu/Menu";
import PageBanner from "../components/section_elements/PageBanner";
import Newsletter from "../components/section_elements/newsletter/Newsletter";
import Partner from "../components/section_elements/partner/Partner";
import Footer from "../components/Footer";
import api from "../utils/api";
import Error from "../components/Error";
import {API_DATA_NOT_AVAILABLE} from "../utils/constants";
import Skeleton from "react-loading-skeleton";
import {currency, setPageTitle} from "../utils/utils";
import {Link} from "react-router-dom";

class Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        api.get("/package")
            .then(response => {
                this.setState({
                    data: response.data["data"],
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.toString(),
                    loading: false,
                });
            });

        setPageTitle("Packages");
    }

    packageItem(data, index) {
        return (
            <div key={index} className="price-table col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="price-item mt_30">
                    <div className="price-header">
                        <h3>{data["package_name"]}</h3>
                        <h2>{data["price"]} {currency()}</h2>
                        <p>{data["validity_period"]}</p>
                        <p className="mt-4">{data["description"]}</p>
                    </div>
                    <div className="price-body">
                        <ul>
                            {data["features"].length > 0 && data["features"].map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="price-footer">
                        <div className="button">
                            <Link to={"#"}>Choose Us <i className="fa fa-chevron-circle-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    packagePlaceholderItem() {
        return (
            <div className="col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0.2s">
                <div className="price-item mt_30">
                    <div className="price-header">
                        <h3><Skeleton width={"200px"} height={"30px"}/></h3>
                        <h2><Skeleton width={"150px"} height={"25px"}/></h2>
                        <p><Skeleton width={"100px"} height={"20px"}/></p>
                    </div>
                    <div className="price-body">
                        <ul>
                            <li><Skeleton width={"90%"}/></li>
                            <li><Skeleton width={"90%"}/></li>
                            <li><Skeleton width={"90%"}/></li>
                            <li><Skeleton width={"90%"}/></li>
                        </ul>
                    </div>
                    <div className="price-footer">
                        <div className="button">
                            <Skeleton width={"180px"} height={"40px"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {data, loading, error} = this.state;

        if (error) {
            return <Error message={error}/>
        }

        if (!loading && data.length === 0) {
            return <Error message={API_DATA_NOT_AVAILABLE}/>
        }

        return (
            <>
                <TopNav/>
                <Menu/>
                <PageBanner pageName={"Packages"} banner={null}/>
                <div className="price-table-area pt_40 pb_70">
                    <div className="container">
                        <div className="row ov_hidden">
                            {loading ? (
                                <>
                                    {this.packagePlaceholderItem()}
                                    {this.packagePlaceholderItem()}
                                    {this.packagePlaceholderItem()}
                                </>
                            ) : (
                                data.map((item, index) => (
                                    this.packageItem(item, index)
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <Newsletter/>
                <Partner/>
                <Footer/>
            </>
        );
    }
}

export default Package;