export const API_DATA_NOT_AVAILABLE = "API: Data not available";

export const SECTION_BLOG_POSTS_LIMIT = 6;

export const SECTION_SERVICE_POSTS_LIMIT = 6;

export const SECTION_TEAM_POSTS_LIMIT = 6;
export const SECTION_TESTIMONIALS_POSTS_LIMIT = 5;
export const SECTION_LOADING_BACKGROUND = "#f7f7f7";

export const BLOG_PAGINATION_DATA_LIMIT = 6;
export const TESTIMONIAL_PAGINATION_DATA_LIMIT = 6;
export const GALLERY_PAGINATION_DATA_LIMIT = 6;