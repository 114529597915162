// const config = {
//     apiUrl: "http://127.0.0.1:8000/api",
//     //  apiUrl: "http://192.168.0.179:8000/api",
//     backendUrl: "http://127.0.0.1:8000",
//     // backendUrl: "http://192.168.0.179:8000",
//     apiKey: "Bearer 6FiskqUeWK8fSiwr9ixud5erHuOGDCdA"
// };

const config = {
    apiUrl: "https://admin.ddmg.io/api",
    backendUrl: "https://admin.ddmg.io",
    apiKey: "Bearer 6FiskqUeWK8fSiwr9ixud5erHuOGDCdA"
};

export default config;
