import React from "react";
import {AsyncImage} from "loadable-image";
import Media from "../../../utils/Media";
import {Blur} from "transitions-kit";

export default class ClientSayItem extends React.Component {
    render() {
        const {data} = this.props;
        const image = Media.imageUrl(data["media"]);

        return (
            <div className="testimonial-item"
                 style={{backgroundImage: "url(./img/shape-3.png)", backgroundSize: "contain"}}>
                <p>{data["summary"]}</p>
                <div className="testi-info" style={{padding: 0, display: "flex", alignItems: "center"}}>
                    <AsyncImage
                        src={image}
                        Transition={Blur}
                        style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                            float: "left"
                        }}
                    />
                    <div className="ml-4">
                        <h4>{data["name"]}</h4>
                        <span>{data["company"]}</span>
                    </div>
                </div>
            </div>
        );
    }
}
