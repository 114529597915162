import React, {Component} from "react";
import Skeleton from "react-loading-skeleton";

class VideoGalleryPlaceholderItem extends Component {
    render() {
        return (
            <div className="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="2, 3"
                 data-sort="Menu">
                <div className="gallery-photo hover-item">
                    <Skeleton width={"100%"} height={300}/>
                </div>
            </div>
        );
    }
}

export default VideoGalleryPlaceholderItem;